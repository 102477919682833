export const formatSourceDateToOutput = (sourceDate) => {
    let tempDate = new Date(sourceDate);

    let year = tempDate.getFullYear();
    let month = (tempDate.getMonth() + 1) < 10
        ? "0" + (tempDate.getMonth() + 1)
        : (tempDate.getMonth() + 1);

    let date = tempDate.getDate() < 10
        ? "0" + tempDate.getDate()
        : tempDate.getDate();

    let details = tempDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    
    return `${date}/${month}/${year} ${details}`;
}

export const formatJavascriptDateToAPI = (unformatedDate) => {
    let year = unformatedDate.getFullYear();
    let month = (unformatedDate.getMonth() + 1) < 10
        ? "0" + (unformatedDate.getMonth() + 1)
        : (unformatedDate.getMonth() + 1);

    let date = unformatedDate.getDate() < 10
        ? "0" + unformatedDate.getDate()
        : unformatedDate.getDate();

    let hours = unformatedDate.getHours() < 10
        ? "0" + unformatedDate.getHours()
        : unformatedDate.getHours();

    let minutes = unformatedDate.getMinutes() < 10
        ? "0" + unformatedDate.getMinutes()
        : unformatedDate.getMinutes();

    let seconds = unformatedDate.getSeconds() < 10
        ? "0" + unformatedDate.getSeconds()
        : unformatedDate.getSeconds();

    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}