import Axios from "axios";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { formatSourceDateToOutput } from "../../../_helper/Date";

const RecipientTable = ({ studentSemesterIds, setStudentSemesterIds, messageId = null, editable = true }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [studentSemesterName, setStudentSemesterName] = useState(null);

    const token = localStorage.getItem('auth_token');

    const _ = require("lodash");

    useEffect(() => {
        fetchStudentSemesters();
    }, [studentSemesterIds, studentSemesterName]);

    const fetchStudentSemesters = async () => {
        setLoading(true);

        let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/recipients`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                student_semester_ids: studentSemesterIds,
                message_id: messageId,
                name: studentSemesterName
            }
        });
                
        setData(response.data.data);
        setLoading(false);
    }

    const removeStudentSemester = (studentSemesterId) => {
        setStudentSemesterIds(studentSemesterIds.filter((id) => id != studentSemesterId));
    }

    const handleChangeSelection = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const contextActions = useMemo(() => {
		const handleDelete = () => {
			if (window.confirm(`Are you sure you want to remove Recipients?`)) {
				setToggleClearRows(!toggledClearRows);

                for (let i = 0; i < studentSemesterIds.length; i++) {
                    setStudentSemesterIds(studentSemesterIds.filter((id) => {
                        return selectedRows.find((currentValue, index, arr) => {
                            return currentValue == id;
                        });
                    }));
                }
			}
		};

		return (
			<Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
				Remove
			</Button>
		);
	}, [data, selectedRows, toggledClearRows]);

    const columns = (messageId)
        ? [
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: true,
                grow: 1
            },
            {
                id: "class_name",
                name: "Class",
                selector: (row) => row.class_name,
                sortable: true,
                grow: 1
            },
            {
                id: "package_name",
                name: "Package",
                selector: (row) => row.package_name,
                sortable: true,
                grow: 1
            },
            {
                id: "branch_code",
                name: "Branch Code",
                selector: (row) => row.branch_code,
                sortable: true,
            },
            {
                id: "read_at",
                name: "Read At",
                selector: (row) => row.read_at,
                cell: (row) => row.read_at ? formatSourceDateToOutput(row.read_at) : null,
                sortable: true,
            },
            {
                cell: (row) => {
                    return editable
                        ? (<button onClick={() => removeStudentSemester(row.semester_id)} className="btn btn-sm btn-danger">Remove</button>)
                        : null;
                },
            }
        ]
        : [
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: true,
                grow: 1
            },
            {
                id: "class_name",
                name: "Class",
                selector: (row) => row.class_name,
                sortable: true,
                grow: 1
            },
            {
                id: "package_name",
                name: "Package",
                selector: (row) => row.package_name,
                sortable: true,
                grow: 1
            },
            {
                id: "branch_code",
                name: "Branch Code",
                selector: (row) => row.branch_code,
                sortable: true,
            },
            {
                cell: (row) => {
                    return editable
                        ? (<button onClick={() => removeStudentSemester(row.semester_id)} className="btn btn-sm btn-danger">Remove</button>)
                        : null;
                },
            }
        ];
    
    return (
        <>
            <FormGroup className="d-flex flex-column mb-0">
                <Label for="class-input" className="p-0 pb-0">
                    Student Name
                </Label>
                <div className="p-0 pt-2">
                    <Input
                        id="class-input"
                        type="text"
                        name="class"
                        className="px-4 py-2"
                        value={studentSemesterName}
                        onChange={(event) => setStudentSemesterName(event.target.value)}
                        />
                </div>
            </FormGroup>

            <hr className="opacity-25" />

            {
                editable
                ? <DataTable
                    title=" "
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    contextActions={contextActions}
                    pagination
                    selectableRows
                    onSelectedRowsChange={handleChangeSelection}
                    clearSelectedRows={toggledClearRows}
                />
                : <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    contextActions={contextActions}
                    pagination
                    clearSelectedRows={toggledClearRows}
                />
            }
        </>
    ); 
}

export default RecipientTable;

