import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    div
} from "reactstrap";
import { createReactEditorJS } from "react-editor-js";
import HeaderCard from "../Common/Component/HeaderCard";
import { Breadcrumbs, P } from "../../AbstractElements";
import { EDITOR_JS_TOOLS } from "../../Config/Tools";
import edjsParser from "editorjs-parser";
import DataTable from "react-data-table-component";
import RecipientTable from "./components/recipient_table";
import RecipientSelectorForm from "./components/recipient_selector_form";
import Dropzone from "react-dropzone";
import SweetAlert from 'sweetalert2';
import { Navigate, redirect, unstable_HistoryRouter, useParams } from "react-router-dom";
import { INITIAL_EDITOR_JS_DATA } from "../../Constant/editor_js";
import AuthenticatedWrapper from "../../Route/AuthenticatedWrapper";
import ReactDatePicker from "react-datepicker";
import moment from "moment/moment";
import { formatJavascriptDateToAPI } from "../../_helper/Date";
import RequiredLabel from "../Common/Component/RequiredLabel";


const EditMessage = () => {
    const { messageId } = useParams();

    const [reactJsEditor, setReactJsEditor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState();
    const [category, setCategory] = useState("notes and information");
    const [plainMessage, setPlainMessage] = useState("");
    const [urgency, setUrgency] = useState("");
    const [isDraft, setIsDraft] = useState(false);
    const [note, setNote] = useState("");
    const [datetime, setDatetime] = useState("");
    const [studentSemesterIds, setStudentSemesterIds] = useState([]);
    const [sendEmail, setSendEmail] = useState(false);

    const [activeTab, setActiveTab] = useState('1');

    const [redirect, setRedirect] = useState(false);

    const [image, setImage] = useState([]);
    const [attachments, setAttachments] = useState([]);

    const [featuredImageToBeUploaded, setFeaturedImageToBeUploaded] = useState(null);
    const [attachmentsToBeUploaded, setAttachmentsToBeUploaded] = useState([]);

    const [now, setNow] = useState(moment().get());

    const [errors, setErrors] = useState({});

    const token = localStorage.getItem('auth_token');

    let editorCore = React.useRef(null);
    let EditorParser = new edjsParser();
    let ReactEditorJS = createReactEditorJS();    
    
    useEffect(() => {
        fetchMessage();
    }, []);

    const fetchMessage = async () => {
        setLoading(true);

        let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        setSubject(response.data.data.subject);
        setPlainMessage(response.data.data.plain_message);
        setMessage(response.data.data.block_message);
        setCategory(response.data.data.category);
        setUrgency(response.data.data.urgency);
        setIsDraft(response.data.data.is_draft);
        setNote(response.data.data.note);
        setDatetime(
            new Date(
                response.data.data.datetime
                    ? response.data.data.datetime
                    : null
            )
        );
        setStudentSemesterIds(response.data.data.recipients);
        setAttachmentsToBeUploaded(response.data.data.attachments);
        setFeaturedImageToBeUploaded(response.data.data.featured_image);
        setSendEmail(response.data.data.send_email);

        setReactJsEditor(
            <ReactEditorJS
                holder="react-editor-js"
                className="w-100"
                onInitialize={handleInitialize}
                tools={EDITOR_JS_TOOLS}
                required={true}
                data={response.data.data.block_message}
            >
                <div id="react-editor-js"></div>
            </ReactEditorJS>
        );

        setLoading(false);
    };

    useEffect(() => {
        if (featuredImageToBeUploaded)
            setImage([featuredImageToBeUploaded].map(file => (
                <li key={file.path}>
                    <button type="button" onClick={() => removeImage()} className="btn btn-primary mb-2">{file.path}</button>
                </li>
            )));
    }, [featuredImageToBeUploaded]);

    useEffect(() => {
        setAttachments(attachmentsToBeUploaded.map((file, index) => (
            <li key={`${file.path}-${index}-attachments`}>
                <button type="button" onClick={() => removeAttachmentByIndex(index)} className="btn btn-primary mb-2">{file.path}</button>
            </li>
        )));
    }, [attachmentsToBeUploaded]);

    const handleImageDrop = (acceptedFiles) => {
        console.log(acceptedFiles);

        setFeaturedImageToBeUploaded(acceptedFiles[0]);
    };

    const removeImage = () => {        
        setFeaturedImageToBeUploaded(null);
        setImage([]);
    }

    const handleAttachmentsDrop = (acceptedFiles) => {
        console.log(acceptedFiles);

        setAttachmentsToBeUploaded([...acceptedFiles, ...attachmentsToBeUploaded]);
    };

    const removeAttachmentByIndex = (index) => {
        let newAttachmentsToBeUploaded = [...attachmentsToBeUploaded];

        newAttachmentsToBeUploaded.splice(index, 1);

        setAttachmentsToBeUploaded(newAttachmentsToBeUploaded);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    };

    const createMessageRequest = async (isDraft = false) => {
        document.getElementById("message-form").reportValidity();

        let formData = new FormData();

        const savedData = await editorCore.current.save();

        formData.append('urgency', urgency);
        formData.append('category', category);
        formData.append('subject', subject);
        formData.append('block_message', JSON.stringify(savedData));
        formData.append('message', EditorParser.parse(savedData));
        formData.append('plain_message', plainMessage);
        formData.append('note', note);
        formData.append('send_email', sendEmail);
        formData.append('datetime', datetime
            ? formatJavascriptDateToAPI(datetime)
            : null);
        formData.append('subject', subject);
        
        for (let i = 0; i < studentSemesterIds.length; i++)
            formData.append('recipients[]', studentSemesterIds[i]);

        formData.append('is_draft', isDraft);

        if (featuredImageToBeUploaded)
            formData.append('featured_image', featuredImageToBeUploaded);

        for (let i = 0; i < attachmentsToBeUploaded.length; i++) {
            if (attachmentsToBeUploaded[i] instanceof File) {
                formData.append('attachments[]', attachmentsToBeUploaded[i]);
            } else {
                formData.append(`attachments[${i}][id]`, attachmentsToBeUploaded[i].id);
                formData.append(`attachments[${i}][name]`, attachmentsToBeUploaded[i].name);
                formData.append(`attachments[${i}][path]`, attachmentsToBeUploaded[i].path);
            }
        }

        try {
            let response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}/update`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log(response);

            SweetAlert.fire("success", "Message updated successfully");

            setRedirect(true);
        } catch (error) {
            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
                SweetAlert.fire("error", "Something wrong with the input");
            } else {
                console.log(error.repon)
            }
        }
    };

    const handleInitialize = React.useCallback((instance) => {
        editorCore.current = instance;
    }, []);

    return (
        <AuthenticatedWrapper>
            <Fragment>
                {redirect && (<Navigate to="/messages" replace={true} />)}
                
                <Breadcrumbs
                    parent="Messages"
                    title="Edit Messages"
                    mainTitle="Edit Messages"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="8">
                            <form id="message-form" onSubmit={(event) => handleSubmit(event)}>
                                <Card>
                                    <HeaderCard title="Message Detail" />
                                    <CardBody className="pt-2">
                                        <div className="tabbed-card">
                                            <Nav className="pull-right  nav-pills nav-primary">
                                                <NavItem>
                                                    <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                        Detail
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                        Recipients
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <P attrPara={{ className: 'row mt-3' }}>
                                                        <Row>
                                                            <FormGroup className="col-6">
                                                                <div className="border rounded h-100">
                                                                    <Label for="email-input" className="p-4 pb-0">Subject <RequiredLabel /></Label>
                                                                    <Input
                                                                        id="subject-input"
                                                                        name="subject"
                                                                        placeholder="subject"
                                                                        type="text"
                                                                        className="border-0 outline-0 px-4 py-2"
                                                                        onChange={(event) => setSubject(event.target.value)}
                                                                        value={subject}
                                                                        required={true}
                                                                    />
                                                                    {(errors.subject) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.subject[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-6">
                                                                <div className="border rounded">
                                                                    <Label for=""className="p-4 pb-0">Urgency <RequiredLabel /></Label>
                                                                    <FormGroup className="custom-radio-ml d-flex ms-2 mb-0 px-4">
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio1"
                                                                                type="radio"
                                                                                name="urgency"
                                                                                value="emergency"
                                                                                required={true}
                                                                                onChange={(event) => setUrgency(event.target.value)}
                                                                                checked={urgency == "emergency"}
                                                                            />
                                                                            <Label for="radio1">
                                                                                Emergency
                                                                            </Label>
                                                                        </div>
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio3"
                                                                                type="radio"
                                                                                name="urgency"
                                                                                value="important"
                                                                                required={true}
                                                                                onChange={(event) => setUrgency(event.target.value)}
                                                                                checked={urgency == "important"}
                                                                            />
                                                                            <Label for="radio3">
                                                                                Important
                                                                            </Label>
                                                                        </div>
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio4"
                                                                                type="radio"
                                                                                name="urgency"
                                                                                value="normal"
                                                                                required={true}
                                                                                onChange={(event) => setUrgency(event.target.value)}
                                                                                checked={urgency == "normal"}
                                                                            />
                                                                            <Label for="radio4">Normal</Label>
                                                                        </div>
                                                                    </FormGroup>
                                                                    {(errors.urgency) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.urgency[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-12">
                                                                <div className="border rounded">
                                                                    <Label for=""className="p-4 pb-0">Category <RequiredLabel /></Label>
                                                                    <FormGroup className="custom-radio-ml d-flex ms-2 mb-0 px-4">
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio1-category"
                                                                                type="radio"
                                                                                name="category"
                                                                                value="notes and information"
                                                                                required={true}
                                                                                onChange={(event) => setCategory(event.target.value)}
                                                                                checked={category == "notes and information"}
                                                                            />
                                                                            <Label for="radio1-category">
                                                                                Notes And Information
                                                                            </Label>
                                                                        </div>
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio3-category"
                                                                                type="radio"
                                                                                name="category"
                                                                                value="student activities"
                                                                                required={true}
                                                                                onChange={(event) => setCategory(event.target.value)}
                                                                                checked={category == "student activities"}
                                                                            />
                                                                            <Label for="radio3-category">
                                                                                Student Activities
                                                                            </Label>
                                                                        </div>
                                                                        <div className="radio radio-primary me-4">
                                                                            <Input
                                                                                id="radio4-category"
                                                                                type="radio"
                                                                                name="category"
                                                                                value="announcement"
                                                                                required={true}
                                                                                onChange={(event) => setCategory(event.target.value)}
                                                                                checked={category == "announcement"}
                                                                            />
                                                                            <Label for="radio4-category">Announcement</Label>
                                                                        </div>
                                                                    </FormGroup>
                                                                    {(errors.category) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.category[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-12">
                                                                <div className="border rounded">
                                                                    <Label for=""className="p-4 pb-0">Additional Message Channel</Label>
                                                                    <FormGroup className="custom-checkbox-ml d-flex ms-2 mb-0 px-4">
                                                                        <div className="checkbox checkbox-primary me-4">
                                                                            <Input
                                                                                id="checkbox1"
                                                                                type="checkbox"
                                                                                name="sendEmail"
                                                                                value="true"
                                                                                required={true}
                                                                                onChange={(event) => setSendEmail(sendEmail == "true" ? null : "true")}
                                                                                checked={sendEmail == "true"}
                                                                            />
                                                                            <Label for="checkbox1">
                                                                                Send Email
                                                                            </Label>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-12">
                                                                <div className="p-4 border rounded">
                                                                    <Label for="message-input">Message (This is the message content for Email and In-App Notification) <RequiredLabel /></Label>
                                                                    {reactJsEditor}

                                                                    {(errors.message) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.message[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>

                                                            {/* <FormGroup className="col-12">
                                                                <div className="p-4 border rounded">
                                                                    <Label for="message-input">Plain Message (This is the message content for WhatsApp and SMS)</Label>
                                                                    <Input
                                                                            id="checkbox-primary-1"
                                                                            name="plain_message"
                                                                            placeholder="plain message"
                                                                            type="textarea"
                                                                            onChange={(event) => setPlainMessage(event.target.value)}
                                                                            value={plainMessage}
                                                                        />
                                                                    {(errors.message) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.message[0]}</div> : null}
                                                                </div>
                                                            </FormGroup> */}

                                                            <FormGroup className="col-6">
                                                                <Card>
                                                                    <HeaderCard title="Featured Image" />
                                                                    <CardBody>
                                                                        <div className="dz-message needsclick">
                                                                            <Dropzone onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles)} maxFiles={1}>
                                                                                {({getRootProps, getInputProps}) => (
                                                                                    <section>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <p>Drag 'n' drop image here, or click to select image</p>
                                                                                    </div>
                                                                                    </section>
                                                                                )}
                                                                            </Dropzone>
                                                                            <div className="py-4">
                                                                                <h4>Image</h4>
                                                                                <ul className="position-relative">{image}</ul>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </FormGroup>

                                                            <FormGroup className="col-6">
                                                                <Card>
                                                                    <HeaderCard title="Attachments" />
                                                                    <CardBody>
                                                                        <div className="dz-message needsclick">
                                                                            <Dropzone onDrop={(acceptedFiles) => handleAttachmentsDrop(acceptedFiles)} maxFiles={5}>
                                                                                {({getRootProps, getInputProps}) => (
                                                                                    <section>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div>
                                                                                    </section>
                                                                                )}
                                                                            </Dropzone>
                                                                            <div className="py-4">
                                                                                <h4>Attachments</h4>
                                                                                <ul className="position-relative">{attachments}</ul>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </FormGroup>

                                                            <FormGroup className="col-12">
                                                                <div className="border rounded">
                                                                    <Label for="message-input" className="p-4 pb-0">Note </Label>
                                                                    <Input
                                                                        id="note-input"
                                                                        name="note"
                                                                        placeholder="note"
                                                                        type="textarea"
                                                                        className="border-0 outline-0 px-4 py-2"
                                                                        onChange={(event) => setNote(event.target.value)}
                                                                        value={note}
                                                                    />
                                                                    {(errors.note) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.note[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-12">
                                                                <div className="border rounded">
                                                                    <Label for="message-input" className="p-4 pb-0">Schedule Datetime</Label>
                                                                    <div className="d-flex flex-column">
                                                                        <ReactDatePicker
                                                                            className="form-control digits w-100 border-0 outline-0 p-4 e-5"
                                                                            showTimeSelect
                                                                            selected={datetime}
                                                                            onChange={(update) => { setDatetime(update);}}
                                                                            isClearable={true}
                                                                            dateFormat={"dd/M/yyyy h:mm aa"}
                                                                            minDate={new Date()} />
                                                                    </div>

                                                                    {(errors.datetime) ? <div className="invalid-feedback d-block px-4 pb-4">{errors.datetime[0]}</div> : null}
                                                                </div>
                                                            </FormGroup>
                                                        </Row>
                                                    </P>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <P attrPara={{ className: 'mt-3' }}>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <Card>
                                                                        <CardBody>
                                                                        <RecipientSelectorForm
                                                                            setStudentSemesterIds={setStudentSemesterIds}
                                                                            studentSemesterIds={studentSemesterIds}
                                                                            />
                                                                            {(errors.recipients) ? <div className="invalid-feedback d-block px-4 py-4">{errors.recipients[0]}</div> : null}
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </P>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <Col sm="12 d-flex justify-content-end">
                                            <button onClick={() => createMessageRequest(true)} type="submit" className="btn btn-danger me-2">Save as draft</button>
                                            <button onClick={() => createMessageRequest(false)} type="submit" className="btn btn-primary">Send</button>
                                        </Col>
                                    </CardFooter>
                                </Card>
                            </form>
                        </Col>
                        <Col sm="4">
                            <Card>
                                <CardBody>
                                    <RecipientTable
                                        setStudentSemesterIds={setStudentSemesterIds}
                                        studentSemesterIds={studentSemesterIds}
                                        />
                                    {(errors.recipients) ? <div className="invalid-feedback d-block px-4 py-4">{errors.recipients[0]}</div> : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </AuthenticatedWrapper>
    );
};

export default EditMessage;
