import { H5 } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { CardHeader } from 'reactstrap';

const HeaderCard = ({ title, span1, span2 }) => {
    return (
        <Fragment>
            <CardHeader className="pb-0 d-flex align-items-center">
                <H5 className="mb-0">{title}</H5>
                {span1 ? <span className="ms-3 mt-0">{span1}</span> : ''}
                {span2 ? <span className="ms-2 mt-0">{span2}</span> : ''}
            </CardHeader>
        </Fragment>
    );
};

export default HeaderCard;