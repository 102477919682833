import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Input, Row, Col, Button, Spinner } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import Swal from 'sweetalert2';

const AttendanceEditModal = ({ isOpen, onToggle, type, student, onSave, dataCheckIn, dataCheckOut, dataTemperature }) => {
    const token = localStorage.getItem('auth_token');
    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState(type);
    const [attendanceStatus, setAttendanceStatus] = useState(student ? student.attendance_status : '');
    const [checkInDate, setCheckInDate] = useState(new Date().toISOString().substr(0, 10));
    const [timeTaken, setTimeTaken] = useState('');
    const [bacaanSuhu, setBacaanSuhu] = useState('');
    const [isLateChecked, setIsLateChecked] = useState(false);
    const [lateRemarks, setLateRemarks] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [optionRemarks, setOptionRemarks] = useState('');

    const handleAttendanceChange = (status) => {
        setAttendanceStatus(status);
    }

    const handleLateToggle = (e) => {
        setIsLateChecked(e.target.checked);
    }

    const handleOptionChange = (option) => {
        if (selectedOption === option) {
            setSelectedOption('');
        } else {
            setSelectedOption(option);
        }
    }

    const formatDateTimeToYYYYMMDDHHMM = (dateStr, timeStr) => {
        const [hour, minute] = timeStr.split(':');
        return `${dateStr} ${hour}:${minute}`;
    };    

    useEffect(() => {
        setActiveTab(type);
        
        if (type === 'editCheckIn' && dataCheckIn) {
            const metaData = JSON.parse(dataCheckIn.metadata);
        
            setAttendanceStatus(dataCheckIn.status);
            setCheckInDate(dataCheckIn.date);
            setTimeTaken(dataCheckIn.timestamp.split(' ')[1]);
            setBacaanSuhu(metaData.temperature || '');
            setIsLateChecked(metaData.is_late === "1");
            setLateRemarks(metaData.remark || '');
        }
    
        if (type === 'editBreakTime' && dataTemperature) {
            const metaData = JSON.parse(dataTemperature.metadata);
    
            setCheckInDate(dataTemperature.date);
            setTimeTaken(dataTemperature.timestamp.split(' ')[1]);
            setBacaanSuhu(metaData.temperature || '');
        }
    
        if (type === 'editCheckOut' && dataCheckOut) {
            const metaData = JSON.parse(dataCheckOut.metadata);
    
            setCheckInDate(dataCheckOut.date);
            setTimeTaken(dataCheckOut.timestamp.split(' ')[1]);
            setBacaanSuhu(metaData.temperature || '');
    
            if (metaData.is_excuse === "1") {
                setSelectedOption('excuses');
            } else if (metaData.is_overtime === "1") {
                setSelectedOption('overtime');
            } else {
                setSelectedOption('');
            }
    
            setOptionRemarks(metaData.remark || '');
        }
    }, [type, dataCheckIn, dataTemperature, dataCheckOut]);    

    const handleSubmit = async () => {
        const typeMap = {
            'editCheckIn': 0,
            'editBreakTime': 1,
            'editCheckOut': 2
        };
        const typeValue = typeMap[activeTab];
    
        let currentAttendanceStatus = attendanceStatus;
        if (activeTab === 'editBreakTime' || activeTab === 'editCheckOut') {
            currentAttendanceStatus = 'attend';
        }
        const remark = activeTab === 'editCheckIn' ? lateRemarks : optionRemarks;

        let attendanceId;
        if (type === 'editCheckIn' && dataCheckIn) {
            attendanceId = dataCheckIn.id;
        }
        if (type === 'editBreakTime' && dataTemperature) {
            attendanceId = dataTemperature.id;
        }
        if (type === 'editCheckOut' && dataCheckOut) {
            attendanceId = dataCheckOut.id;
        }
    
        const formData = {
            id: student.student_semester_id,
            temperature: bacaanSuhu,
            is_late: isLateChecked ? "1" : "0",
            is_excuse: selectedOption === 'excuses' ? "1" : "0",
            is_overtime: selectedOption === 'overtime' ? "1" : "0",
            timestamp: formatDateTimeToYYYYMMDDHHMM(checkInDate, timeTaken),
            type: typeValue,
            remark: remark,
            checkInDate: checkInDate,
            attendanceStatus: currentAttendanceStatus
        };
        setLoading(true);
        try {
            const response = await sendEditData(formData, attendanceId);
            console.log("Response from server:", response);
            onToggle();
            onSave();
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setLoading(false);
    };

    const sendEditData = async (data, attendanceId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/react/attendances/${attendanceId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            if (response.status === 200) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Data edited successfully!',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error editing data',
            });
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={onToggle} centered backdrop="static">
            <ModalHeader toggle={onToggle}>
                {type === 'editCheckIn' && 'Edit Check In'}
                {type === 'editBreakTime' && 'Edit Break Temperature'}
                {type === 'editCheckOut' && 'Edit Check Out'}
            </ModalHeader>
            <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'editCheckIn' })}
                                onClick={() => setActiveTab('editCheckIn')}
                                disabled={activeTab !== 'editCheckIn'}
                            >
                                Check In
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'editBreakTime' })}
                                onClick={() => setActiveTab('editBreakTime')}
                                disabled={activeTab !== 'editBreakTime'}
                            >
                                Temperature
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'editCheckOut' })}
                                onClick={() => setActiveTab('editCheckOut')}
                                disabled={activeTab !== 'editCheckOut'}
                            >
                                Check Out
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="editCheckIn">
                            <Form>
                                <FormGroup>
                                    <Label for="studentName">Student Name</Label>
                                    <Label className="d-block">{student ? student.name : 'N/A'}</Label>
                                </FormGroup>
                                <FormGroup tag="fieldset">
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="attendanceStatus"
                                                        value="attend"
                                                        onChange={() => handleAttendanceChange('attend')}
                                                        checked={attendanceStatus === 'attend'}
                                                        className='radio_animated form-check-input'
                                                    />{' '}
                                                    Present
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Label check>
                                                <Input
                                                    type="radio"
                                                    name="attendanceStatus"
                                                    value="absent"
                                                    onChange={() => handleAttendanceChange('absent')}
                                                    checked={attendanceStatus === 'absent'}
                                                    className='radio_animated form-check-input'
                                                />{' '}
                                                Absent
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input 
                                        type="time" 
                                        id="timeTaken" 
                                        value={timeTaken} 
                                        onChange={(e) => setTimeTaken(e.target.value)} 
                                        placeholder="Enter check-in time" 
                                        disabled={attendanceStatus === 'absent'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu" 
                                        disabled={attendanceStatus === 'absent'}
                                        className='mb-2'
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    disabled={attendanceStatus === 'absent'}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="isLate">Late?</Label>
                                    <Input 
                                        type="checkbox" 
                                        id="isLate" 
                                        onChange={handleLateToggle} 
                                        checked={isLateChecked} 
                                        disabled={attendanceStatus === 'absent'}
                                        className='checkbox_animated form-check-input'
                                    />
                                </FormGroup>
                                {isLateChecked && attendanceStatus !== 'absent' && (
                                    <FormGroup>
                                        <Label for="lateRemarks">Late Remarks</Label>
                                        <Input 
                                            type="textarea" 
                                            id="lateRemarks" 
                                            placeholder="Enter remarks" 
                                            onChange={(e) => setLateRemarks(e.target.value)} 
                                            value={lateRemarks}
                                            disabled={attendanceStatus === 'absent'}
                                        />
                                    </FormGroup>
                                )}
                            </Form>
                        </TabPane>
                        <TabPane tabId="editBreakTime">
                            <Form>
                                <FormGroup>
                                    <Label for="studentName">Student Name</Label>
                                    <Label className="d-block">{student ? student.name : 'N/A'}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input type="time" id="timeTaken" value={timeTaken} onChange={(e) => setTimeTaken(e.target.value)} placeholder="Enter check-in time" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu"
                                        className='mb-2' 
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId="editCheckOut">
                            <Form>
                                <FormGroup>
                                    <Label for="studentName">Student Name</Label>
                                    <Label className="d-block">{student ? student.name : 'N/A'}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input type="time" id="timeTaken" value={timeTaken} onChange={(e) => setTimeTaken(e.target.value)} placeholder="Enter check-in time" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu" 
                                        className='mb-2'
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input 
                                            type="checkbox" 
                                            name="option" 
                                            value="excuses"
                                            onChange={() => handleOptionChange('excuses')} 
                                            checked={selectedOption === 'excuses'} 
                                            className='checkbox_animated form-check-input'
                                        />{' '}
                                        Excuses
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input 
                                            type="checkbox" 
                                            name="option" 
                                            value="overtime"
                                            onChange={() => handleOptionChange('overtime')} 
                                            checked={selectedOption === 'overtime'} 
                                            className='checkbox_animated form-check-input'
                                        />{' '}
                                        Overtime
                                    </Label>
                                </FormGroup>

                                {(selectedOption === 'excuses' || selectedOption === 'overtime') && (
                                    <FormGroup>
                                        <Label for="optionRemarks">Remarks</Label>
                                        <Input type="textarea" id="optionRemarks" placeholder="Enter remarks" onChange={(e) => setOptionRemarks(e.target.value)} value={optionRemarks} />
                                    </FormGroup>
                                )}
                            </Form>
                        </TabPane>
                    </TabContent>
                </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onToggle}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? <Spinner size="sm" color="light" /> : "Save"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AttendanceEditModal;