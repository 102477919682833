import { Home, File, CheckSquare } from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Admin",
    menucontent: "Dashboards,Widgets",
    Items: [
      { path: `${process.env.PUBLIC_URL}/attendances/calendar`, icon: Home, type: "link", title: "Dashboard" },
      { path: `${process.env.PUBLIC_URL}/attendances/dashboard`, icon: CheckSquare, type: "link", title: "Attendance Entry" },
      {
        title: "Messages",
        icon: File,
        type: "sub",
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/messages`, title: "List", type: "link" },
          { path: `${process.env.PUBLIC_URL}/messages/create`, title: "Create", type: "link" },
        ],
      },
      { path: `${process.env.PUBLIC_URL}/app-log`, icon: Home, type: "link", title: "App Activity Log" },
    ],
  },
];
