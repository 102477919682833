import Axios from "axios";
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardBody, TabContent, CardHeader, TabPane, Nav, NavItem, NavLink, Container, Row, Badge } from 'reactstrap';
import { Breadcrumbs, H6, P, H5, Image, UL, LI } from "../../AbstractElements";
import HeaderCard from "../Common/Component/HeaderCard";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import MessageStatus from "./components/MessageStatus";
import MessageUrgency from "./components/MessageUrgency";
import RecipientTable from "./components/recipient_table";
import CardHeaderComponent from "../Dashboard/Common/CardHeader";
import { formatSourceDateToOutput } from "../../_helper/Date";

const MessageDetail = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/messages`;

    const { messageId } = useParams();

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({});

    const [activeTab, setActiveTab] = useState('1');

    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        getMessage(messageId);
    }, []);

    const getMessage = async (messageId) => {
        setLoading(true);

        let response = await Axios.get(`${baseUrl}/${messageId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        setMessage(response.data.data);
        setLoading(false);
    };

    return (
        <Fragment>
            <Breadcrumbs
                parent="Messages"
                title="Messages Detail"
                mainTitle="Messages Detail"
            />
            <Container fluid={true}>
                <Row>
                    <Col lg="12" className='box-col-12 xl-100'>
                        <Card>
                            <HeaderCard
                                title={message.subject}
                                span1={<MessageStatus status={message.status} />}
                                span2={<MessageUrgency urgency={message.urgency} />} />
                            <CardBody className="pt-2">
                                <div className="tabbed-card">
                                    <Nav className="pull-right  nav-pills nav-primary">
                                        <NavItem>
                                            <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                Detail
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                Recipients
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <P attrPara={{ className: 'row mt-3' }}>
                                                <Col sm="3" className="d-flex  mb-4 ">
                                                    <img src={message.featured_image ? message.featured_image.url : ''} className="rounded bg-light w-100" style={{ 'min-height' : '100px' }} />
                                                </Col>

                                                <Col sm="9">
                                                    <Row className="m-0 p-0 w-100 pb-5">
                                                        <Col sm="12">
                                                            <P attrPara={{ className: 'f-13 mb-0 font-roboto', style: { color: "#999" } }}>Sender:</P>
                                                            <H6 attrH6={{ className: 'f-14 f-w-600 txt-primary' }}>{message.sender_name}</H6>
                                                        </Col>
                                                        <Col sm="12" id="message-content" className="py-4">
                                                            {message.message && parse(message.message)}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <hr className="opacity-25"></hr>

                                                <Col sm="6" className="d-flex flex-column align-items-center">
                                                    <Row className="m-0 p-0 w-100">
                                                        <Col sm="4" className="p-0 d-flex align-items-center fw-bold text-muted">Note</Col>
                                                        <Col sm="8" className="p-0 d-flex align-items-center">{message.note}</Col>
                                                    </Row>
                                                </Col>
                                                <Col sm="6" className="d-flex flex-column align-items-center">
                                                    <Row className="m-0 p-0 w-100">
                                                        <Col sm="4" className="p-0 d-flex align-items-center fw-bold text-muted">Scheduled Datetime</Col>
                                                        <Col sm="8" className="p-0 d-flex align-items-center">{message.datetime}</Col>
                                                    </Row>
                                                </Col>

                                                <hr className="opacity-25 mt-3"></hr>

                                                <Col sm="12" className="d-flex flex-column align-items-center">
                                                    <Row className="m-0 p-0 w-100">
                                                        <Col sm="12" className="p-0 d-flex align-items-center fw-bold text-muted">Attachments</Col>
                                                        {message.attachments
                                                            ? message.attachments.map(attachment => {
                                                                return <Col sm="12" className="p-0 d-flex align-items-center">
                                                                    <a href={attachment.url} download={attachment.name}>{attachment.path}</a>
                                                                </Col>;
                                                            })
                                                            : null
                                                        }
                                                    </Row>
                                                </Col>
                                            </P>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <P attrPara={{ className: 'row mt-3' }}>
                                                <RecipientTable
                                                    studentSemesterIds={message.recipients ? message.recipients : []}
                                                    editable={false}
                                                    messageId={messageId}
                                                    />
                                            </P>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>

                        <Card>
                            <HeaderCard title="Activity Timeline" />
                            <CardBody>
                                {
                                    !message.activities
                                        ? null
                                        : <div className="chart-main activity-timeline update-line">
                                            {
                                                message.activities.map((activity, index) => {
                                                    return (
                                                        <div className="media">
                                                            { index == 0 ? <div className="activity-line"></div> : null}
                                                            <div className="activity-dot-primary"></div>
                                                            <div className="media-body d-block">
                                                                <H6> <span className="font-primary me-2">{formatSourceDateToOutput(activity.created_at)}</span></H6>
                                                                <H5>Updated Message <i className="fa fa-circle circle-dot-primary pull-right"></i></H5>
                                                                <P>By {activity.causer}</P>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default MessageDetail;