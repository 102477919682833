import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const AppLogFilter = ({
    branchFilter, onBranchFilterChange,
    onYearIntakeChange,
}) => {
    const currentYear = new Date().getFullYear();

    const [fetchedBranches, setFetchedBranches] = useState([]);
    const years = [currentYear - 1, currentYear, currentYear + 1];
    
    const token = localStorage.getItem('auth_token');
    const headersConfig = {
        headers: { 'Authorization': `Bearer ${token}` }
    };

    const initialBranchId = fetchedBranches.length ? fetchedBranches[0].branch_id : null;

    const [branchId, setBranchId] = useState(initialBranchId);
    const [packageId, setPackageId] = useState(null);
    const [catId, setCatId] = useState(null);
    const [yearIntake, setYearIntake] = useState(currentYear);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/react/getBranches`, headersConfig)
        .then(({ data }) => {
            if (Array.isArray(data.data)) {
                setFetchedBranches(data.data);
                if (data.data.length) {
                    setBranchId(data.data[0].branch_id);
                }
            }
        })
        .catch(error => console.error("Error fetching branches:", error.message));
    }, [yearIntake]);

    useEffect(() => {
        onBranchFilterChange(branchId);
        onYearIntakeChange(yearIntake);
    }, [branchId, yearIntake]);    

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="6" xl="6">
                    <label htmlFor="year-intake">Year Intake</label>
                    <Select
                        defaultValue={{ value: currentYear, label: currentYear }}
                        options={years.map(year => ({
                            value: year,
                            label: year
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setYearIntake(selectedOption.value);
                            onYearIntakeChange(selectedOption.value);
                            setBranchId(fetchedBranches[0]?.branch_id || null);
                            setPackageId('');
                            setCatId('');
                        }}
                    />
                </Col>
                <Col sm="6" xl="6">
                    <label htmlFor="branches">Branches</label>
                    <Select
                        isClearable
                        value={{ value: branchId, label: fetchedBranches.find(branch => branch.branch_id === branchId)?.code }}
                        options={fetchedBranches.map(branch => ({
                            value: branch.branch_id,
                            label: branch.code
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setBranchId(selectedOption ? selectedOption.value : null);
                            setPackageId(null);
                            setCatId(null);
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default AppLogFilter;