import { useEffect, useState } from "react"
import { Badge } from "reactstrap";

const MessageUrgency = ({urgency = ""}) => {
    const [messageUrgencyColor, setMessageUrgencyColor] = useState(null);
    
    useEffect(() => {
        let tempColor;

        switch (urgency) {
            case "emergency":
                tempColor = "danger";
                break;
            case "important":
                tempColor = "warning";
                break;
            default:
                tempColor = "primary";
                break;
        }

        setMessageUrgencyColor(tempColor);
    }, []);

    return <Badge color={messageUrgencyColor}>{urgency}</Badge>;
}

export default MessageUrgency;