import React, { Fragment, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Container, Card, CardBody, Row, Col, Input } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AuthenticatedWrapper from '../../Route/AuthenticatedWrapper';
import AppLogFilter from './AppLogFilter';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ActivityLog } from '../../Constant';

const AppLog = () => {
    const cancelSourceRef = useRef(null);

    const [attendances, setAttendances] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);

    const [filterText, setFilterText] = useState('');
    const [branchId, setBranchId] = useState('');
    const [yearIntake, setYearIntake] = useState(new Date().getFullYear());

    const filteredItems = useMemo(() => {
        if (filterText && typeof filterText === "string") {
            return attendances.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
        }
        return attendances;
    }, [attendances, filterText]);

    const [gridOptions, setGridOptions] = useState({
        paginationPageSize: 10,
        paginationPageSizes: [10, 25, 50, 100],
        pagination: true,
    });

    useEffect(() => {
        setToggleCleared(!toggleCleared); 
        getActivityLog();
        return () => {
            if (cancelSourceRef.current) {
                cancelSourceRef.current.cancel('Component unmounted, request canceled.');
            }
        }
    }, [branchId, yearIntake]);    

    const getActivityLog = async () => {
        try {
            setLoading(true);
            if (cancelSourceRef.current) {
                cancelSourceRef.current.cancel('Previous request was canceled due to a new request.');
            }
    
            cancelSourceRef.current = axios.CancelToken.source();
    
            const token = localStorage.getItem('auth_token');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/react/activity-log`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    branch: branchId,
                    year_intake: yearIntake,
                },
                cancelToken: cancelSourceRef.current.token
            });
    
            setLoading(false);
            setAttendances(data.data);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request was canceled', error.message);
            } else {
                setLoading(false);
                handleErrors(error);
            }
        }
    }    
    
    const handleErrors = (error) => {
        if (error.response && error.response.status === 401) {
            console.log("Token is not valid or expired");
            localStorage.removeItem("auth_token");
        } else {
            console.error("An error occurred while fetching attendances:", error);
        }
    }

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onPageSizeChanged = (newPageSize) => {
        gridApi.paginationSetPageSize(Number(newPageSize));
        setGridOptions(prevOptions => ({
            ...prevOptions,
            paginationPageSize: Number(newPageSize)
        }));
    };    
    
    const columnDefs = [
        {
            headerName: "Student Name",
            field: "student_name",
            width: 450,
            sortable: true,
            resizable: true,
            filter: true,
        },
        {
            headerName: "Parent Name",
            field: "parent_name",
            width: 450,
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Package",
            field: "package",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Class",
            field: "class",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Page Visited",
            field: "page_visited",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Time Visited",
            field: "time_visited",
            sortable: true,
            resizable: true,
        },
    ];

    return (
        <AuthenticatedWrapper>
            <Fragment>
                <Breadcrumbs parent="Log" title="App Activity Log" mainTitle="App Activity Log" />
                <Container fluid={true} className="">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <AppLogFilter
                                        branchFilter={branchId}
                                        onBranchFilterChange={setBranchId}
                                        onYearIntakeChange={setYearIntake}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="ag-theme-alpine">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
                                            <span style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>Rows Per Page : </span>
                                            <Input
                                                bsSize="sm"
                                                type="select"
                                                name="pageSize"
                                                style={{ width: 'auto' }}
                                                onChange={(e) => onPageSizeChanged(e.target.value)}
                                                value={gridOptions.paginationPageSize}
                                            >
                                                {gridOptions.paginationPageSizes.map(size => (
                                                    <option key={size} value={size}>{size}</option>
                                                ))}
                                            </Input>
                                        </div>
                                        <AgGridReact
                                            columnDefs={columnDefs}
                                            rowData={filteredItems}
                                            domLayout='autoHeight'
                                            onGridReady={onGridReady}
                                            rowSelection="multiple"
                                            suppressRowClickSelection={true}
                                            gridOptions={gridOptions}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </AuthenticatedWrapper>
    );
};

export default AppLog;