import { useEffect, useState } from "react"
import { Badge } from "reactstrap";

const MessageStatus = ({status = ""}) => {
    const [messageStatusColor, setMessageStatusColor] = useState(null);
    
    useEffect(() => {
        let tempColor;

        switch (status) {
            case "sent":
                tempColor = "success";
                break;
            case "draft":
                tempColor = "primary";
                break;
            default:
                tempColor = "info";
                break;
        }

        setMessageStatusColor(tempColor);
    }, []);

    return <Badge color={messageStatusColor}>{status}</Badge>;
}

export default MessageStatus;