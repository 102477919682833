import React, { useState, Fragment, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Input, Row, Col, Spinner } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import Swal from 'sweetalert2';

const AttendanceModalBulk = ({ isOpen, type, students, onToggle, onSave, checkInDateFromFilter }) => {
    const token = localStorage.getItem('auth_token');
    const [activeTab, setActiveTab] = useState(type);
    const [loading, setLoading] = useState(false);

    const formatDateToYYYYMMDD = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };   

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const formattedTime = currentDate.toTimeString().split(' ')[0].slice(0, 5);
    const [checkInDate, setCheckInDate] = useState(checkInDateFromFilter ? formatDateToYYYYMMDD(new Date(checkInDateFromFilter)) : formattedDate);
    const [timeTaken, setTimeTaken] = useState(formattedTime);
    const [bacaanSuhu, setBacaanSuhu] = useState('36.0');
    const [attendanceStatus, setAttendanceStatus] = useState("attend"); 
    const [isLateChecked, setIsLateChecked] = useState(false);
    const [lateRemarks, setLateRemarks] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [optionRemarks, setOptionRemarks] = useState('');

    const handleLateToggle = () => setIsLateChecked(!isLateChecked);
    const handleOptionChange = (option) => setSelectedOption(option);

    const handleAttendanceChange = (status) => {
        setAttendanceStatus(status);
    };
    
    useEffect(() => {
        setActiveTab(type);
    
        if (checkInDateFromFilter) {
            setCheckInDate(formatDateToYYYYMMDD(new Date(checkInDateFromFilter)));
        } else {
            setCheckInDate(formattedDate);
        }
    }, [type, checkInDateFromFilter]);    

    const combineDateAndTime = (date, time) => {
        const [year, month, day] = date.split('-');
        const [hour, minute] = time.split(':');
        const combinedDate = new Date(year, month - 1, day, hour, minute);
        return formatDateTimeToYYYYMMDDHHMMSS(combinedDate);
    };
    
    const formatDateTimeToYYYYMMDDHHMMSS = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        const ss = String(date.getSeconds()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    };     
    
    const resetModalState = () => {
        setActiveTab(type);
        setCheckInDate(formatDateToYYYYMMDD(new Date(checkInDateFromFilter)));
        setTimeTaken(formattedTime);
        setBacaanSuhu('');
        setAttendanceStatus("attend");
        setIsLateChecked(false);
        setLateRemarks('');
        setSelectedOption('');
        setOptionRemarks('');
    };    

    const handleSubmit = async () => {
        const typeMap = {
            'bulkCheckIn': 0,
            'bulkTemperature': 1,
            'bulkCheckOut': 2
        };
        const typeValue = typeMap[activeTab];

        let currentAttendanceStatus = attendanceStatus;
        if (activeTab === 'bulkTemperature' || activeTab === 'bulkCheckOut') {
            currentAttendanceStatus = 'attend';
        }
        const remark = activeTab === 'bulkCheckIn' ? lateRemarks : optionRemarks;
    
        const bulkFormData = students.map(student => ({
            id: student.student_semester_id,
            temperature: bacaanSuhu,
            is_late: isLateChecked ? "1" : "0",
            is_excuse: selectedOption === 'excuses' ? "1" : "0",
            is_overtime: selectedOption === 'overtime' ? "1" : "0",
            timestamp: combineDateAndTime(checkInDate, timeTaken),
            type: typeValue,
            remark: remark,
            checkInDate: checkInDate,
            attendanceStatus: attendanceStatus
        }));
        setLoading(true);
        try {
            const response = await sendBulkCheckInData(bulkFormData);
            console.log("Response from server:", response);
            resetModalState();
            onToggle();
            onSave();
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setLoading(false);
    };    
    
    const sendBulkCheckInData = async (bulkData) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/react/attendances/bulk`,
                { students: bulkData },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
    
            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Data sent successfully!',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error sending data',
            });
        }
    };

    return (
        <Fragment>
            <Modal isOpen={isOpen} toggle={onToggle} centered backdrop="static">
                <ModalHeader toggle={onToggle}>
                    {type === 'bulkCheckIn' && 'Bulk Check In'}
                    {type === 'bulkTemperature' && 'Bulk Temperature'}
                    {type === 'bulkCheckOut' && 'Bulk Check Out'}
                </ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'bulkCheckIn' })}
                                onClick={() => setActiveTab('bulkCheckIn')}
                                disabled={activeTab !== 'bulkCheckIn'}
                            >
                                Check In
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'bulkTemperature' })}
                                onClick={() => setActiveTab('bulkTemperature')}
                                disabled={activeTab !== 'bulkTemperature'}
                            >
                                Temperature
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'bulkCheckOut' })}
                                onClick={() => setActiveTab('bulkCheckOut')}
                                disabled={activeTab !== 'bulkCheckOut'}
                            >
                                Check Out
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="bulkCheckIn">
                            <Form>
                                <Label for="studentName" className='mt-2'>
                                    Student Name ({students ? students.length : 0})
                                </Label>
                                <FormGroup style={{maxHeight: '300px', overflowY: 'auto', border: '1px solid #e0e0e0', padding: '5px'}}>
                                    {students && students.length > 0 && (
                                        <>
                                            {students.map(student => (
                                                <Label key={student.id} className="d-block">{student.name}</Label>
                                            ))}
                                        </>
                                    )}
                                </FormGroup>
                                <FormGroup tag="fieldset">
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="attendanceStatus"
                                                        value="attend"
                                                        onChange={() => handleAttendanceChange('attend')}
                                                        checked={attendanceStatus === 'attend'}
                                                        className='radio_animated form-check-input'
                                                    />{' '}
                                                    Present
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Label check>
                                                <Input
                                                    type="radio"
                                                    name="attendanceStatus"
                                                    value="absent"
                                                    onChange={() => handleAttendanceChange('absent')}
                                                    checked={attendanceStatus === 'absent'}
                                                    className='radio_animated form-check-input'
                                                />{' '}
                                                Absent
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input 
                                        type="time" 
                                        id="timeTaken" 
                                        value={timeTaken} 
                                        onChange={(e) => setTimeTaken(e.target.value)} 
                                        placeholder="Enter check-in time" 
                                        disabled={attendanceStatus === 'absent'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu" 
                                        disabled={attendanceStatus === 'absent'}
                                        className='mb-2'
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    disabled={attendanceStatus === 'absent'}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="isLate">Late?</Label>
                                    <Input 
                                        type="checkbox" 
                                        id="isLate" 
                                        onChange={handleLateToggle} 
                                        checked={isLateChecked} 
                                        disabled={attendanceStatus === 'absent'}
                                        className='checkbox_animated form-check-input'
                                    />
                                </FormGroup>
                                {isLateChecked && attendanceStatus !== 'absent' && (
                                    <FormGroup>
                                        <Label for="lateRemarks">Late Remarks</Label>
                                        <Input 
                                            type="textarea" 
                                            id="lateRemarks" 
                                            placeholder="Enter remarks" 
                                            onChange={(e) => setLateRemarks(e.target.value)} 
                                            value={lateRemarks}
                                            disabled={attendanceStatus === 'absent'}
                                        />
                                    </FormGroup>
                                )}
                            </Form>
                        </TabPane>
                        <TabPane tabId="bulkTemperature">
                            <Form>
                                <Label for="studentName" className='mt-2'>
                                    Student Name ({students ? students.length : 0})
                                </Label>
                                <FormGroup style={{maxHeight: '300px', overflowY: 'auto', border: '1px solid #e0e0e0', padding: '5px'}}>
                                    {students && students.length > 0 && (
                                        <>
                                            {students.map(student => (
                                                <Label key={student.id} className="d-block">{student.name}</Label>
                                            ))}
                                        </>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input type="time" id="timeTaken" value={timeTaken} onChange={(e) => setTimeTaken(e.target.value)} placeholder="Enter check-in time" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu" 
                                        className='mb-2'
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId="bulkCheckOut">
                            <Form>
                                <Label for="studentName" className='mt-2'>
                                    Student Name ({students ? students.length : 0})
                                </Label>
                                <FormGroup style={{maxHeight: '300px', overflowY: 'auto', border: '1px solid #e0e0e0', padding: '5px'}}>
                                    {students && students.length > 0 && (
                                        <>
                                            {students.map(student => (
                                                <Label key={student.id} className="d-block">{student.name}</Label>
                                            ))}
                                        </>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="date" id="checkInDate" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} placeholder="Enter check-in date" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="timeTaken">Time Taken</Label>
                                    <Input type="time" id="timeTaken" value={timeTaken} onChange={(e) => setTimeTaken(e.target.value)} placeholder="Enter check-in time" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bacaanSuhu">Bacaan Suhu</Label>
                                    <Input 
                                        type="text" 
                                        id="bacaanSuhu" 
                                        value={bacaanSuhu}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                            if (regex.test(val)) {
                                                if (val === "") {
                                                    setBacaanSuhu(val);
                                                } else if (parseFloat(val) > 50.00) {
                                                    setBacaanSuhu("50.00");
                                                } else if (parseFloat(val) < 20.00 && val.length >= 2 && !val.includes('.')) {
                                                    setBacaanSuhu("20.00");
                                                } else {
                                                    setBacaanSuhu(val);
                                                }
                                            }
                                        }}
                                        placeholder="Enter bacaan suhu"
                                        className='mb-2' 
                                    />
                                    <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            marginBottom: '10px', 
                                            overflowX: 'auto', 
                                            width: '100%', 
                                            whiteSpace: 'nowrap'
                                        }}>
                                        {[...Array(11).keys()].map(i => {
                                            const value = (36.0 + i * 0.1).toFixed(1);
                                            return (
                                                <button 
                                                    type="button"
                                                    key={value}
                                                    style={{ 
                                                        margin: '0 2px', 
                                                        padding: '2px 5px', 
                                                        fontSize: '12px' 
                                                    }}
                                                    onClick={() => setBacaanSuhu(value)}
                                                    className='btn btn-outline-primary btn-sm'
                                                >
                                                    {value}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input 
                                            type="checkbox" 
                                            name="option" 
                                            value="excuses"
                                            onChange={() => handleOptionChange('excuses')} 
                                            checked={selectedOption === 'excuses'} 
                                            className='checkbox_animated form-check-input'
                                        />{' '}
                                        Excuse
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input 
                                            type="checkbox" 
                                            name="option" 
                                            value="overtime"
                                            onChange={() => handleOptionChange('overtime')} 
                                            checked={selectedOption === 'overtime'} 
                                            className='checkbox_animated form-check-input'
                                        />{' '}
                                        Overtime
                                    </Label>
                                </FormGroup>

                                {(selectedOption === 'excuses' || selectedOption === 'overtime') && (
                                    <FormGroup>
                                        <Label for="optionRemarks">Remarks</Label>
                                        <Input type="textarea" id="optionRemarks" placeholder="Enter remarks" onChange={(e) => setOptionRemarks(e.target.value)} value={optionRemarks} />
                                    </FormGroup>
                                )}
                            </Form>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onToggle}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default AttendanceModalBulk;