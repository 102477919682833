import React, { Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import AuthenticatedWrapper from '../../../Route/AuthenticatedWrapper';
import axios from 'axios';
import AttendanceFilter from './AttendanceFilter';
import ReactApexChart from 'react-apexcharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const AttendanceCalendar = () => {
    const [attendances, setAttendances] = useState([]);
    const [loading, setLoading] = useState(false);
    const cancelTokenSource = axios.CancelToken.source();

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const [filterText, setFilterText] = useState('');
    const [branchId, setBranchId] = useState('');
    const [packageId, setPackageId] = useState('');
    const [classId, setClassId] = useState('');
    const [yearIntake, setYearIntake] = useState(new Date().getFullYear());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth();

    const getDaysInMonth = (month, year) => {
        return Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => i + 1);
    };

    useEffect(() => {
        getAttendances();
        return () => {
            cancelTokenSource.cancel('Canceled due to component unmount or new request');
        };
    }, [branchId, packageId, classId, filterText, yearIntake, selectedMonth]);
    
    useEffect(() => {
        const daysInMonth = getDaysInMonth(selectedMonth + 1, selectedDate.getFullYear());
    
        const presentCount = Array(daysInMonth.length).fill(0);
        const absentCount = Array(daysInMonth.length).fill(0);
        
        attendances.forEach(student => {
            const dailyStatuses = Array(daysInMonth.length).fill([]).map(() => []);
            student.attendance_dates.forEach(ad => {
                const attendanceDate = new Date(ad.date);
                const day = attendanceDate.getDate() - 1;
    
                dailyStatuses[day].push(ad.status);
            });
    
            dailyStatuses.forEach((statuses, index) => {
                const absentOccurrences = statuses.filter(status => status === "absent").length;
                if (absentOccurrences > 0) {
                    absentCount[index]++;
                } else {
                    const attendOccurrences = statuses.filter(status => status === "attend").length;
                    if (attendOccurrences > 0) {
                        presentCount[index]++;
                    }
                }
            });
        });
        
        setSeries([
            {
                name: 'Present',
                data: presentCount
            },
            {
                name: 'Absent',
                data: absentCount
            }
        ]);
    
    }, [attendances, selectedMonth, selectedDate]);    

    const getAttendances = () => {
        setLoading(true);
        const token = localStorage.getItem('auth_token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/react/attendances/calendar`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                branch: branchId,
                package: packageId,
                class: classId,
                filterText: filterText,
                year_intake: yearIntake,
                month: selectedMonth + 1,
                year: selectedDate.getFullYear()
            },
            cancelToken: cancelTokenSource.token
        })
        .then(({ data }) => {
            setLoading(false);
            setAttendances(data.data);
        })
        .catch((error) => {
            setLoading(false);
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else if (error.response && error.response.status === 401) {
                console.log("Token is not valid or expired");
                localStorage.removeItem("auth_token");
            } else {
                console.error("An error occurred while fetching attendances:", error);
            }
        });
    }

    const Legend = () => {
        return (
            <div className="d-flex align-items-center mb-3">
                <LegendItem color="green" text="P = Present" />
                <LegendItem color="orange" text="PL = Present but Late" />
                <LegendItem color="orange" text="PX = Present with Excuse" />
                <LegendItem color="orange" text="PO = Present with Overtime" />
                <LegendItem color="red" text="A = Absent" />
            </div>
        );
    };
    
    const LegendItem = ({ color, text }) => {
        return (
            <div className="d-flex align-items-center me-3">
                <span style={{ 
                    backgroundColor: color, 
                    width: '15px', 
                    height: '15px', 
                    display: 'inline-block', 
                    marginRight: '5px',
                    borderRadius: '50%' 
                }}></span>
                {text}
            </div>
        );
    };

    const columnDefs = [
        {
            headerName: 'Student Name',
            field: 'name',
            width: 450,
            pinned: 'left',
            cellRenderer: (params) => (
                <span>
                    {params.value.toUpperCase()}
                </span>
            ),
            resizable: true,
        },
        {
            headerName: 'Package',
            field: 'package_name',
            pinned: 'left',
            width: 250,
            resizable: true,
        },
        {
            headerName: 'P',
            field: 'attendance_dates',
            width: 50,
            cellRenderer: (params) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
        
                const uniqueDates = Array.from(new Set(params.value.map((ad) => ad.date)));
        
                const validPresentDates = uniqueDates.filter((date) => {
                const attendanceForDate = params.value.filter((ad) => ad.date === date);
        
                if (attendanceForDate.some((ad) => ad.status === 'absent')) {
                    return false;
                }
        
                const attendanceDate = new Date(date);
                attendanceDate.setHours(0, 0, 0, 0);
        
                return attendanceDate <= today;
                });
        
                return validPresentDates.length;
            },
            cellStyle: {
                textAlign: 'center',
            },
            resizable: true,
        },
        {
            headerName: 'A',
            field: 'attendance_dates',
            width: 50,
            cellRenderer: (params) => {
                const absentDates = params.value
                .filter((ad) => ad.status === 'absent')
                .map((ad) => ad.date);
                const uniqueAbsentDates = Array.from(new Set(absentDates));
                return uniqueAbsentDates.length;
            },
            cellStyle: {
                textAlign: 'center',
            },
            resizable: true,
        },
        ...getDaysInMonth(selectedMonth + 1, selectedDate.getFullYear()).map((day) => {
            const date = new Date(selectedDate.getFullYear(), selectedMonth, day);
            const dayName = dayNames[date.getDay()];
            const formattedDay = String(day).padStart(2, '0');
    
            const isToday = todayDate === day && todayMonth === selectedMonth;
            const bgColor = isToday ? '#e0f7ff' : 'transparent';
    
            return {
                headerName: `${formattedDay}\n${dayName}`,
                wrapHeaderText: true,
                resizable: true,
                cellRenderer: (params) => {
                    const attendanceDate = new Date(selectedDate.getFullYear(), selectedMonth, day);
                    const today = new Date();
                    if (attendanceDate > today) return '';
                
                    const formattedDay = String(day).padStart(2, '0');
                    const attendancesForTheDay = params.data.attendance_dates.filter(
                        ad => ad.date === `${selectedDate.getFullYear()}-${String(selectedMonth + 1).padStart(2, '0')}-${formattedDay}`
                    );
                
                    const statusId = `student-${params.data.student_semester_id}-${day}`;
                    let isAbsent = false, isLate = false, isExcuse = false, isOvertime = false, tooltipText = '';
                
                    attendancesForTheDay.forEach((attendance) => {
                        if (attendance.status === 'absent') isAbsent = true;
                        if (attendance.is_late === '1') isLate = true;
                        if (attendance.is_excuse === '1') isExcuse = true;
                        if (attendance.is_overtime === '1') isOvertime = true;
                        if (attendance.remark && !tooltipText) tooltipText = attendance.remark;
                    });
                
                    const generateRemarkList = (remarksArray) => (
                        <ul>
                            {remarksArray.map((remark, idx) => (
                                <li key={idx}>{remark}</li>
                            ))}
                        </ul>
                    );
                
                    const remarks = { late: [], excuse: [], overtime: [] };
                    attendancesForTheDay.forEach((attendance) => {
                        if (attendance.is_late === '1') remarks.late.push(attendance.remark);
                        if (attendance.is_excuse === '1') remarks.excuse.push(attendance.remark);
                        if (attendance.is_overtime === '1') remarks.overtime.push(attendance.remark);
                    });
                
                    let statusText = '';
                    if (isAbsent) statusText = 'A';
                    else if (isLate) statusText = 'PL';
                    else if (isExcuse) statusText = 'PX';
                    else if (isOvertime) statusText = 'PO';
                    else if (attendancesForTheDay.length > 0) statusText = 'P';
                
                    return (
                        <div>
                            <span
                                title={
                                    (remarks.late.length > 0 ? `Late: ${remarks.late.join(', ')}` : '') +
                                    (remarks.excuse.length > 0 ? `\nExcuse: ${remarks.excuse.join(', ')}` : '') +
                                    (remarks.overtime.length > 0 ? `\nOvertime: ${remarks.overtime.join(', ')}` : '')
                                }
                                style={{
                                    color: isAbsent ? 'red' : (statusText === 'P' ? 'green' : 'orange'),
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                            >
                                {statusText}
                            </span>
                        </div>
                    );
                },
                cellStyle: {
                    backgroundColor: bgColor,
                    textAlign: 'center'
                },
                width: 70
            };
        })       
    ];

    const [series, setSeries] = useState([
        {
            name: 'Present',
        }, 
        {
            name: 'Absent',
        }
    ]);

    const daysInMonth = getDaysInMonth(selectedMonth + 1, selectedDate.getFullYear());

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        colors: ['#BDD871', '#FF9DBB'],
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            type: 'category',
            categories: daysInMonth.map(day => String(day)),
            title: {
                text: 'Days in Month',
                style: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Total',
                style: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },     
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
        },
        fill: {
            opacity: 1
        },
        title: {
            text: `${monthNames[selectedMonth]} ${selectedDate.getFullYear()} Daily Attendances`,
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
                color: 'black'
            }
        },
    };
    
    const exportToExcel = (rowData) => {
        let formattedData = [];
    
        rowData.forEach(row => {
            getDaysInMonth(selectedMonth + 1, selectedDate.getFullYear()).forEach(day => {
                const dateStr = `${selectedDate.getFullYear()}-${String(selectedMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                const attendancesForTheDay = row.attendance_dates.filter(ad => ad.date === dateStr);
    
                if (attendancesForTheDay.length) {
                    let formattedRow = {};
    
                    formattedRow['STUDENT NAME'] = row['name'];
                    formattedRow['BRANCH'] = row['branch_code'];
                    formattedRow['PACKAGE'] = row['package_name'];
                    formattedRow['CLASS'] = row['class_name'];
                    formattedRow['DATE'] = dateStr;
    
                    const statusRecord = attendancesForTheDay.find(ad => ad.status);
                    formattedRow['ATTENDANCE STATUS'] = statusRecord ? statusRecord.status : '';

                    if (formattedRow['status'] !== 'absent') {
                        const timeInRecord = attendancesForTheDay.find(ad => ad.type === 0);
                        const timeOutRecord = attendancesForTheDay.find(ad => ad.type === 2);
                    
                        formattedRow['TIME IN'] = timeInRecord ? timeInRecord.time : "";
                        formattedRow['TIME OUT'] = timeOutRecord ? timeOutRecord.time : "";
                    }
                    
                    const lateRemark = attendancesForTheDay.find(ad => ad.is_late === "1");
                    const excuseRemark = attendancesForTheDay.find(ad => ad.is_excuse === "1");
                    const overtimeRemark = attendancesForTheDay.find(ad => ad.is_overtime === "1");
                    
                    formattedRow['REMARK LATE'] = lateRemark ? lateRemark.remark || "Late" : "";
                    formattedRow['REMARK EXCUSES'] = excuseRemark ? excuseRemark.remark || "Excused" : "";
                    formattedRow['REMARK OVERTIME'] = overtimeRemark ? overtimeRemark.remark || "Overtime" : "";                    
    
                    formattedData.push(formattedRow);
                }
            });
        });
    
        const ws = XLSX.utils.json_to_sheet(formattedData, { cellStyles: true });
    
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'].forEach(cell => {
            ws[cell].s = { font: { bold: true } };
        });
    
        const monthName = monthNames[selectedMonth];
        const year = selectedDate.getFullYear();
        const filename = `${monthName}_${year}.xlsx`;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    
        saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    };    

    return (
        <AuthenticatedWrapper>
            <Fragment>
                <Breadcrumbs parent="Attendances" title="Attendances Calendar" mainTitle="Attendances Calendar" />
                <Container fluid={true} className="">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <AttendanceFilter 
                                        filterText={filterText}
                                        onTextFilterChange={setFilterText}
                                        branchFilter={branchId}
                                        onBranchFilterChange={setBranchId}
                                        packageFilter={packageId}
                                        onPackageFilterChange={setPackageId}
                                        classFilter={classId}
                                        onClassFilterChange={setClassId}
                                        onYearIntakeChange={setYearIntake}
                                        onMonthChange={setSelectedMonth}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <ReactApexChart options={options} series={series} type="bar" height={500} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <h3 className="text-center mb-3">{monthNames[selectedMonth]} {selectedDate.getFullYear()}</h3>
                                    <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Legend style={{ flexBasis: '75%', flexGrow: 1 }} />
                                        <Button color="primary" onClick={() => exportToExcel(attendances, columnDefs)}>Export to Excel</Button>
                                    </div>
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            columnDefs={columnDefs}
                                            rowData={attendances}
                                            domLayout="autoHeight"
                                            rowSelection="multiple"
                                            pagination={true}
                                            paginationPageSize={10}
                                            suppressRowClickSelection={true}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </AuthenticatedWrapper>
    );
};

export default AttendanceCalendar;