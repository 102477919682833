import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword } from "../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/1.png";
import SocialAuth from "./Tabs/LoginTab/SocialAuth";

const Login = ({ selected }) => {
  const [ic, setIc] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL") || man);
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/react/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ic, user_pwd: password })
        });

        const data = await response.json();

        if (data.status === 'success') {
          localStorage.setItem("login", true);
          localStorage.setItem("auth_token", data.token);
          history('/attendances/dashboard');
          setLoading(false);
        } else {
            toast.error(data.message);
            setLoading(false);
        }
    } catch (error) {
        toast.error("Something went wrong. Please try again!");
        setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="p-0 container-fluid">
        <Row>
          <Col className="col-12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>Sign </H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">IC</Label>
                    <Input className="form-control" type="text" required="" onChange={(e) => setIc(e.target.value)} value={ic} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} required="" />
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ms-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      {ForgotPassword}
                    </a>
                    <Btn attrBtn={{ type: "button", color: "primary", className: "btn-block", disabled: loading, onClick: (e) => loginAuth(e) }}>Login</Btn>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Login;
