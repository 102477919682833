import React, { Fragment, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import AuthenticatedWrapper from '../../../Route/AuthenticatedWrapper';
import AttendanceFilter from './AttendanceFilter';
import AttendanceModal from './AttendanceModal';
import AttendanceModalBulk from './AttendanceModalBulk';
import AttendanceEditModal from './AttendanceEditModal';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import ProfileDetailsModal from './ProfileDetailsModal';
import ReactApexChart from 'react-apexcharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AttendanceDashboard = () => {
    const cancelSourceRef = useRef(null);

    const [attendances, setAttendances] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [clearRowsToggle, setClearRowsToggle] = useState(false);

    const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
    const [attendanceModalBulkOpen, setAttendanceModalBulkOpen] = useState(false);
    const [attendanceEditModalOpen, setAttendanceEditModalOpen] = useState(false);

    const [filterText, setFilterText] = useState('');
    const [branchId, setBranchId] = useState('');
    const [packageId, setPackageId] = useState('');
    const [classId, setClassId] = useState('');
    const [yearIntake, setYearIntake] = useState(new Date().getFullYear());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedAttendance, setSelectedAttendance] = useState(null);
    
    const formatDateToYYYYMMDD = (date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }
    const [modalMode, setModalMode] = useState('create');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const [profileModalOpen, setProfileModalOpen] = useState(false);

    const handleAddDataClick = useCallback((row, type) => {
        setSelectedStudent(row);
        if (type === '0') {
            setModalMode('checkIn');
        } else if (type === '1') {
            setModalMode('breakTime');
        } else if (type === '2') {
            setModalMode('checkOut');
        }
        setAttendanceModalOpen(true);
    }, []);
    
    const handleEditClick = useCallback((row, type) => {
        setSelectedStudent(row);
        if (type === '0') {
            setModalMode('editCheckIn');
        } else if (type === '1') {
            setModalMode('editBreakTime');
        } else if (type === '2') {
            setModalMode('editCheckOut');
        }
        setAttendanceEditModalOpen(true);
    }, []);

    const filteredItems = useMemo(() => {
        if (filterText && typeof filterText === "string") {
            return attendances.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
        }
        return attendances;
    }, [attendances, filterText]);

    useEffect(() => {
        setSelectedRows([]);
        setToggleCleared(!toggleCleared); 
        getAttendances();
        return () => {
            if (cancelSourceRef.current) {
                cancelSourceRef.current.cancel('Component unmounted, request canceled.');
            }
        }
    }, [branchId, packageId, classId, filterText, yearIntake, selectedDate, selectedAttendance]);    

    const getAttendances = async () => {
        try {
            setLoading(true);
            if (cancelSourceRef.current) {
                cancelSourceRef.current.cancel('Previous request was canceled due to a new request.');
            }
    
            cancelSourceRef.current = axios.CancelToken.source();
    
            const token = localStorage.getItem('auth_token');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/react/attendances`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    branch: branchId,
                    package: packageId,
                    class: classId,
                    filterText: filterText,
                    year_intake: yearIntake,
                    attendance: selectedAttendance,
                    date: formatDateToYYYYMMDD(selectedDate),
                },
                cancelToken: cancelSourceRef.current.token
            });
    
            setLoading(false);
            setAttendances(data.data);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request was canceled', error.message);
            } else {
                setLoading(false);
                handleErrors(error);
            }
        }
    }    
    
    const handleErrors = (error) => {
        if (error.response && error.response.status === 401) {
            console.log("Token is not valid or expired");
            localStorage.removeItem("auth_token");
        } else {
            console.error("An error occurred while fetching attendances:", error);
        }
    }

    const handleBulkCheckIn = () => {
        setModalMode('bulkCheckIn');
        setAttendanceModalBulkOpen(true);
    };    
    
    const handleBulkTemperature = () => {
        setModalMode('bulkTemperature');
        setAttendanceModalBulkOpen(true);
    };
    
    const handleBulkCheckOut = () => {
        setModalMode('bulkCheckOut');
        setAttendanceModalBulkOpen(true);
    };

    const handleNameClick = (row) => {
        setSelectedStudent(row);
        setProfileModalOpen(true);
    };

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            pinned: 'left',
            width: 50,
            lockPosition: true,
        },
        {
            headerName: "Student Name",
            field: "name",
            width: 450,
            cellRenderer: (params) => (
                <span
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => {
                        handleNameClick(params.data);
                    }}
                >
                    {params.value.toUpperCase()}
                </span>
            ),
            pinned: 'left',
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Package",
            field: "package_name",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "attendance_status",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Check In",
            headerClass: 'text-center',
            children: [
                {
                    headerName: "Time",
                    field: "data_check_in.formatted_timestamp",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) =>
                        params.data.data_check_in && params.data.data_check_in.formatted_timestamp
                            ? <span style={{ color: 'blue' }} onClick={() => handleEditClick(params.data, '0')}>{params.data.data_check_in.formatted_timestamp}</span>
                            : <button className="btn btn-primary" onClick={() => handleAddDataClick(params.data, '0')}>Add Data</button>,
                },
                {
                    headerName: "Temperature",
                    field: "data_check_in.temperature",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) => (
                        <div>
                            {(params.data.data_check_in && params.data.data_check_in.temperature)
                                ? `${params.data.data_check_in.temperature} °C`
                                : 'N/A'}
                        </div>
                    ),
                }
            ],
        },
        {
            headerName: "Temperature",
            headerClass: 'text-center',
            children: [
                {
                    headerName: "Time",
                    field: "data_temperature.formatted_timestamp",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) =>
                        params.data.data_temperature && params.data.data_temperature.formatted_timestamp
                            ? <span style={{ color: 'blue' }} onClick={() => handleEditClick(params.data, '1')}>{params.data.data_temperature.formatted_timestamp}</span>
                            : <button className="btn btn-primary" onClick={() => handleAddDataClick(params.data, '1')}>Add Data</button>,
                },
                {
                    headerName: "Temperature",
                    field: "data_temperature.temperature",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) => (
                        <div>
                            {(params.data.data_temperature && params.data.data_temperature.temperature)
                                ? `${params.data.data_temperature.temperature} °C`
                                : 'N/A'}
                        </div>
                    ),
                }
            ],
        },
        {
            headerName: "Check Out",
            headerClass: 'text-center',
            children: [
                {
                    headerName: "Time",
                    field: "data_check_out.formatted_timestamp",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) =>
                        params.data.data_check_out && params.data.data_check_out.formatted_timestamp
                            ? <span style={{ color: 'blue' }} onClick={() => handleEditClick(params.data, '2')}>{params.data.data_check_out.formatted_timestamp}</span>
                            : <button className="btn btn-primary" onClick={() => handleAddDataClick(params.data, '2')}>Add Data</button>,
                },
                {
                    headerName: "Temperature",
                    field: "data_check_out.temperature",
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) => (
                        <div>
                            {(params.data.data_check_out && params.data.data_check_out.temperature)
                                ? `${params.data.data_check_out.temperature} °C`
                                : 'N/A'}
                        </div>
                    ),
                }
            ],
        },
    ];   
    
    const handleRowSelection = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedRows = selectedNodes.map(node => node.data);
        setSelectedRows(selectedRows);
    };    

    const formatDateChart = (dateString) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'Jun', 'July', 'August', 'September', 'October', 'November', 'December'];
        const date = new Date(dateString);

        const dayName = days[date.getDay()];
        const day = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayName} ${day} ${monthName}, ${year}`;
    }

    const calculateStatistics = () => {
        let totalAbsent = 0;
        let totalAttend = 0;
        let packageStats = {};
    
        attendances.forEach(att => {
            if (!packageStats[att.package_name]) {
                packageStats[att.package_name] = { Attend: 0, Absent: 0 };
            }
    
            if (att.attendance_status === 'Absent') {
                totalAbsent += 1;
                packageStats[att.package_name].Absent += 1;
            } 
            if (att.attendance_status === 'Present') {
                totalAttend += 1;
                packageStats[att.package_name].Attend += 1;
            }
        });
    
        return {
            totalAbsent,
            totalAttend,
            packageStats
        };
    };    

    const stats = calculateStatistics();

    const series = [{
        name: 'Attend',
        data: Object.values(stats.packageStats).map(item => item.Attend)
    }, {
        name: 'Absent',
        data: Object.values(stats.packageStats).map(item => item.Absent)
    }];
    
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: Object.keys(stats.packageStats),
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
        },
        colors: ['#BDD871', '#FF9DBB'],
    };

    return (
        <AuthenticatedWrapper>
            <Fragment>
                <Breadcrumbs parent="Attendances" title="Attendances Dashboard" mainTitle="Attendances Dashboard" />
                <Container fluid={true} className="">
                    <AttendanceModal
                        isOpen={attendanceModalOpen}
                        type={modalMode}
                        student={selectedStudent}
                        checkInDateFromFilter={selectedDate}
                        onToggle={() => setAttendanceModalOpen(!attendanceModalOpen)}
                        onSave={() => {
                            console.log('Data saved!');
                            setModalOpen(false);
                            getAttendances();
                        }}
                    />

                    <AttendanceModalBulk
                        isOpen={attendanceModalBulkOpen}
                        type={modalMode}
                        students={selectedRows}
                        checkInDateFromFilter={selectedDate}
                        onToggle={() => setAttendanceModalBulkOpen(!attendanceModalBulkOpen)}
                        onSave={() => {
                            console.log('Bulk data saved!');
                            setModalOpen(false);
                            getAttendances();
                            setClearRowsToggle(prevState => !prevState);
                        }}
                    />

                    <AttendanceEditModal
                        isOpen={attendanceEditModalOpen}
                        type={modalMode}
                        student={selectedStudent}
                        onToggle={() => setAttendanceEditModalOpen(!attendanceEditModalOpen)}
                        onSave={() => {
                            console.log('Data edited!');
                            setAttendanceEditModalOpen(false);
                            getAttendances();
                        }}
                        dataCheckIn={selectedStudent?.data_check_in}
                        dataCheckOut={selectedStudent?.data_check_out}
                        dataTemperature={selectedStudent?.data_temperature}
                    />

                    <ProfileDetailsModal
                        isOpen={profileModalOpen}
                        student={selectedStudent}
                        onClose={() => setProfileModalOpen(false)}
                        branchName={selectedStudent ? selectedStudent.branch_code : ''}
                        packageName={selectedStudent ? selectedStudent.package_name : ''}
                        className={selectedStudent ? selectedStudent.class_name : ''}
                    />

                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                <AttendanceFilter 
                                    filterText={filterText}
                                    onTextFilterChange={setFilterText}
                                    branchFilter={branchId}
                                    onBranchFilterChange={setBranchId}
                                    packageFilter={packageId}
                                    onPackageFilterChange={setPackageId}
                                    classFilter={classId}
                                    onClassFilterChange={setClassId}
                                    onYearIntakeChange={setYearIntake}
                                    selectedAttendance={selectedAttendance}
                                    onAttendanceChange={setSelectedAttendance}
                                    selectedDate={selectedDate}
                                    onDateChange={setSelectedDate}
                                />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Row sm="12" className="mb-4">
                                        <Col sm="6" className="text-left">
                                            {/* <div className="d-flex align-items-center">
                                                {branchId && <h6>Branch ID: {branchId}</h6>}
                                            </div> */}
                                        </Col>
                                        <Col sm="6" className="text-right">
                                            <div className="d-flex justify-content-end align-items-center">
                                                <h6 className="mr-2">{formatDateChart(selectedDate)}</h6>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col sm="3">
                                            <div className="text-center card" style={{ backgroundColor: '#BDD871' }}>
                                                <div className="card-body">
                                                    <h3>Present</h3>
                                                    <h1>{stats.totalAttend}</h1>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                            <div className="text-center card" style={{ backgroundColor: '#FF9DBB' }}>
                                                <div className="card-body">
                                                    <h3>Absent</h3>
                                                    <h1>{stats.totalAbsent}</h1>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                                </CardBody>
                                <div className="d-flex align-items-center justify-content-end me-3">
                                    <button className="btn btn-primary me-2" onClick={handleBulkCheckIn} disabled={selectedRows.length < 2}>
                                        Bulk Check In
                                    </button>
                                    <button className="btn btn-primary me-2" onClick={handleBulkTemperature} disabled={selectedRows.length < 2}>
                                        Bulk Temperature
                                    </button>
                                    <button className="btn btn-primary" onClick={handleBulkCheckOut} disabled={selectedRows.length < 2}>
                                        Bulk Check Out
                                    </button>
                                </div>
                                <CardBody>
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            columnDefs={columnDefs}
                                            rowData={filteredItems}
                                            domLayout='autoHeight'
                                            onGridReady={onGridReady}
                                            rowSelection="multiple"
                                            onSelectionChanged={handleRowSelection}
                                            pagination={true}
                                            paginationPageSize={10}
                                            suppressRowClickSelection={true}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </AuthenticatedWrapper>
    );
};

export default AttendanceDashboard;