import Axios from "axios";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import HeaderCard from "../Common/Component/HeaderCard";
import { Link, Navigate, redirect } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import AuthenticatedWrapper from '../../Route/AuthenticatedWrapper';
import MessageFilter from "./components/MessageFilter";
import MessageUrgency from "./components/MessageUrgency";
import MessageStatus from "./components/MessageStatus";
import { formatSourceDateToOutput } from "../../_helper/Date";
import { AgGridReact } from "ag-grid-react";

const MessageTable = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/messages`;

    const [redirectUrl, setRedirectUrl] = useState(null);

    const [currentBranchId, setCurrentBranchId] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentKeyword, setCurrentKeyword] = useState("");
    const [currentDateRange, setCurrentDateRange] = useState([null, null]);
    const [currentStartDate, currentEndDate] = currentDateRange;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortColumnDirection, setSortColumnDirection] = useState(null);

    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        fetchData();
    }, [perPage, page, sortColumn, sortColumnDirection, currentStartDate, currentEndDate, currentBranchId, currentCategory, currentKeyword]);

    const fetchData = async () => {
        setLoading(true);

        let parameters = {
            perPage: perPage,
            page: page,
            start_at: currentStartDate,
            until_at: currentEndDate,
            branch_id: currentBranchId,
            category: currentCategory,
            keyword: currentKeyword
        };

        if ((sortColumn, sortColumnDirection)) {
            parameters.sortColumn = sortColumn;
            parameters.sortColumnDirection = sortColumnDirection;
        }

        const response = await Axios.get(`${baseUrl}`, { 
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: parameters
        });

        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setLoading(false);
    };

    const handlePageChange = (page) => setPage(page);
    const handlePerRowsChange = (newPerPage, page) => setPerPage(newPerPage);
    const handleSort = (column, sortDirection, sortedRows) => {
        setSortColumn(column.id, sortDirection);
        setSortColumnDirection(sortDirection);
    };

    const createCopy = async (messageId) => {
        try {
            let response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}/copy`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            SweetAlert.fire("success", "Message copied successfully");
    
            setRedirectUrl(`/messages/${messageId}/edit`);
        } catch (error) {
            SweetAlert.fire("error", "Internal server error");
            setRedirectUrl(`/messages`);
        }
    };

    const resentMessage = async (messageId) => {
        try {
            let response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}/resent`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            SweetAlert.fire("success", "Message resent successfully");
        } catch (error) {
            SweetAlert.fire("error", "Internal server error");
        }
    };

    const deleteMessage = async (messageId) => {
        try {
            let response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            SweetAlert.fire("success", "Message delete successfully");

            fetchData();
        } catch (error) {
            SweetAlert.fire("error", "Internal server error");
        }
    };

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const columnDefs = [
        {
            headerName: "Subject",
            field: "subject",
            width: 450,
            cellRenderer: (params) => (
                <b>{params.value}</b>
            ),
            pinned: 'left',
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Category",
            field: "category",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Urgency",
            field: "urgency",
            cellRenderer: (params) => (
                <MessageUrgency urgency={params.value} />
            ),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Datetime",
            field: "datetime",
            cellRenderer: (params) => (
                formatSourceDateToOutput(params.value)
            ),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "status",
            cellRenderer: (params) => (
                <MessageStatus status={params.value} />
            ),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Branch Codes",
            field: "branches",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Sender",
            field: "sender_name",
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Created At",
            field: "created_at",
            sortable: true,
            resizable: true,
            cellRenderer: (params) => (
                formatSourceDateToOutput(params.value)
            ),
        },
        {
            headerName: "Action",
            sortable: false,
            resizable: false,
            cellRenderer: (params) => (
                <UncontrolledDropdown>
                    <DropdownToggle caret color="dark" size="sm">
                        Action
                    </DropdownToggle>   
                    <DropdownMenu dark>
                        <Link className="dropdown-item" to={`/messages/${params.data.id}`}>Show</Link>
                        <Link className="dropdown-item" to={`/messages/${params.data.id}/preview`} target="_blank">Preview</Link>
                        <li className="dropdown-item" onClick={() => createCopy(params.data.id)}>Copy</li>
                        <Link className="dropdown-item" to={`/messages/${params.data.id}/edit`}>Edit</Link>
                        {
                            (params.data.is_draft)
                                ? null
                                : <li className="dropdown-item" onClick={() => resentMessage(params.data.id)}>Resent</li>
                        }
                        {
                            (params.data.is_draft)
                                ? <li className="dropdown-item" onClick={() => deleteMessage(params.data.id)}>Delete</li>
                                : null
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        }
    ];

    return (
        <AuthenticatedWrapper>
            <Fragment>
                {redirectUrl && (<Navigate to={redirectUrl} replace={true} />)}

                <Breadcrumbs
                    parent="Messages"
                    title="Messages Dashboard"
                    mainTitle="Messages Dashboard"
                />
                <Container fluid={true} className="datatables">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <MessageFilter
                                        currentBranchId={currentBranchId}
                                        setCurrentBranchId={setCurrentBranchId}
                                        currentCategory={currentCategory}
                                        setCurrentCategory={setCurrentCategory}
                                        setCurrentDateRange={setCurrentDateRange}
                                        currentStartDate={currentStartDate}
                                        currentEndDate={currentEndDate}
                                        currentKeyword={currentKeyword}
                                        setCurrentKeyword={setCurrentKeyword}
                                        />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="d-flex flex-row-reverse">
                                    <Link to={`/messages/create`}>
                                        <Button color='primary'>Create Message</Button>
                                    </Link>
                                </CardHeader>
                                <CardBody>
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            columnDefs={columnDefs}
                                            rowData={data}
                                            domLayout='autoHeight'
                                            onGridReady={onGridReady}
                                            pagination={true}
                                            paginationPageSize={10}
                                            />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </AuthenticatedWrapper>
    );
};

export default MessageTable;
