import Axios from "axios";
import { Fragment, useEffect, useState } from "react";
import HeaderCard from "../Common/Component/HeaderCard";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MessageStatus from "../Message/components/MessageStatus";
import MessageUrgency from "../Message/components/MessageUrgency";
import parse from 'html-react-parser';
import { H6, P } from "../../AbstractElements";
import { useParams } from "react-router-dom";

const MessagePreview = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/messages`;

    const { messageId } = useParams();

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({});


    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        getMessageByNotificationId(messageId);
    }, []);

    const getMessageByNotificationId = async (messageId) => {
        setLoading(true);

        let response = await Axios.get(`${baseUrl}/${messageId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        setMessage(response.data.data);
        setLoading(false);
    };

    return (
        <Fragment>
            <Container>
                <Row>
                    <Col lg="12" className='box-col-12 xl-100 pt-3'>
                        <Card>
                            <HeaderCard
                                title={message.subject}
                                span1={<MessageStatus status={message.status} />}
                                span2={<MessageUrgency urgency={message.urgency} />} />

                            <CardBody>
                                <Row>
                                    <Col sm="3" className="d-flex  mb-4 ">
                                        <img src={message.featured_image ? message.featured_image.url : ''} className="rounded bg-light w-100" style={{ 'min-height' : '100px' }} />
                                    </Col>

                                    <Col sm="9">
                                        <Row className="m-0 p-0 w-100 pb-5">
                                            <Col sm="12" id="message-content" className="py-0">
                                                {message.message && parse(message.message)}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <hr className="opacity-25"></hr>

                                    <Col sm="6" className="d-flex flex-column align-items-center">
                                        <Row className="m-0 p-0 w-100">
                                            <Col sm="4" className="p-0 d-flex align-items-center fw-bold text-muted">Note</Col>
                                            <Col sm="8" className="p-0 d-flex align-items-center">{message.note}</Col>
                                        </Row>
                                    </Col>

                                    <hr className="opacity-25 mt-3"></hr>

                                    <Col sm="12" className="d-flex flex-column align-items-center">
                                        <Row className="m-0 p-0 w-100">
                                            <Col sm="12" className="p-0 d-flex align-items-center fw-bold text-muted">Attachments</Col>
                                            {message.attachments
                                                ? message.attachments.map(attachment => {
                                                    return <Col sm="12" className="p-0 d-flex align-items-center">
                                                        <a href={attachment.url}>{attachment.path}</a>
                                                    </Col>;
                                                })
                                                : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>
            </Container>
        </Fragment>
    );
}

export default MessagePreview;