import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const AttendanceFilter = ({
    filterText, onTextFilterChange,
    branchFilter, onBranchFilterChange, 
    packageFilter, onPackageFilterChange,
    classFilter, onClassFilterChange,
    onYearIntakeChange,
    onMonthChange,
}) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const [fetchedBranches, setFetchedBranches] = useState([]);
    const [fetchedPackages, setFetchedPackages] = useState([]);
    const [fetchedClasses, setFetchedClasses] = useState([]);
    const years = [currentYear - 1, currentYear, currentYear + 1];
    
    const token = localStorage.getItem('auth_token');
    const headersConfig = {
        headers: { 'Authorization': `Bearer ${token}` }
    };

    const initialBranchId = fetchedBranches.length ? fetchedBranches[0].branch_id : null;

    const [branchId, setBranchId] = useState(initialBranchId);
    const [packageId, setPackageId] = useState(null);
    const [catId, setCatId] = useState(null);
    const [yearIntake, setYearIntake] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/react/getBranches`, headersConfig)
        .then(({ data }) => {
            if (Array.isArray(data.data)) {
                setFetchedBranches(data.data);
                if (data.data.length) {
                    setBranchId(data.data[0].branch_id);
                }
            }
        })
        .catch(error => console.error("Error fetching branches:", error.message));
    }, [yearIntake]);    

    useEffect(() => {
        if (branchId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/react/getPackages?branch_id=${branchId}`, headersConfig)
            .then(({ data }) => {
                if (Array.isArray(data.data)) {
                    setFetchedPackages(data.data);
                }
            })
            .catch(error => console.error("Error fetching packages:", error.message));
        }
    }, [branchId]);

    useEffect(() => {
        if (branchId && packageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/react/getClasses?branch_id=${branchId}&package_id=${packageId}`, headersConfig)
            .then(({ data }) => {
                if (Array.isArray(data.data)) {
                    setFetchedClasses(data.data);
                }
            })
            .catch(error => console.error("Error fetching classes:", error.message));
        }
    }, [branchId, packageId]);

    useEffect(() => {
        onBranchFilterChange(branchId);
        onPackageFilterChange(packageId);
        onClassFilterChange(catId);
        onYearIntakeChange(yearIntake);
        onMonthChange(selectedMonth);
    }, [branchId, packageId, catId, yearIntake, selectedMonth]);    

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="6" xl="2">
                    <label htmlFor="branches">Student Name</label>
                    <div className="mb-3">
                        <input
                            className="form-control form-control"
                            id="search"
                            type="text"
                            placeholder="Filter By Name"
                            aria-label="Search Input"
                            value={filterText}
                            onChange={e => onTextFilterChange(e.target.value)}
                        />
                    </div>
                </Col>
                <Col sm="6" xl="2">
                    <label htmlFor="year-intake">Year Intake</label>
                    <Select
                        defaultValue={{ value: currentYear, label: currentYear }}
                        options={years.map(year => ({
                            value: year,
                            label: year
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setYearIntake(selectedOption.value);
                            onYearIntakeChange(selectedOption.value);
                            setBranchId(fetchedBranches[0]?.branch_id || null);
                            setPackageId('');
                            setCatId('');
                        }}
                    />
                </Col>
                <Col sm="6" xl="2">
                    <label htmlFor="branches">Branches</label>
                    <Select
                        isClearable
                        value={{ value: branchId, label: fetchedBranches.find(branch => branch.branch_id === branchId)?.code }}
                        options={fetchedBranches.map(branch => ({
                            value: branch.branch_id,
                            label: branch.code
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setBranchId(selectedOption ? selectedOption.value : null);
                            setPackageId(null);
                            setCatId(null);
                        }}
                    />
                </Col>
                <Col sm="6" xl="2">
                    <label htmlFor="packages">Packages</label>
                    <Select
                        isClearable
                        value={{ value: packageId, label: fetchedPackages.find(pkg => pkg.package_id === packageId)?.name }}
                        options={branchId ? fetchedPackages.map(pkg => ({ value: pkg.package_id, label: pkg.name })) : []} // <-- Only show options if branchId is present
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setPackageId(selectedOption ? selectedOption.value : null);
                            setCatId(null);
                        }}
                    />
                </Col>
                <Col sm="6" xl="2">
                    <label htmlFor="classes">Classes</label>
                    <Select
                        isClearable
                        value={{ value: catId, label: fetchedClasses.find(classItem => classItem.cat_id === catId)?.name }}
                        options={fetchedClasses.map(classItem => ({
                            value: classItem.cat_id,
                            label: classItem.name
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setCatId(selectedOption ? selectedOption.value : null);
                        }}
                    />
                </Col>
                <Col sm="6" xl="2">
                    <label htmlFor="month-intake">Month</label>
                    <Select
                        defaultValue={{ value: currentMonth, label: monthNames[currentMonth] }}
                        options={monthNames.map((month, idx) => ({
                            value: idx,
                            label: month
                        }))}
                        className="js-example-basic-single"
                        onChange={selectedOption => {
                            setSelectedMonth(selectedOption.value);
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default AttendanceFilter;