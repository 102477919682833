import React, { useState, useEffect } from 'react';
import { Button, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const MessageFilter = ({
    currentBranchId,
    setCurrentBranchId,
    currentCategory,
    setCurrentCategory,
    setCurrentDateRange,
    currentStartDate,
    currentEndDate,
    currentKeyword,
    setCurrentKeyword
}) => {

    const token = localStorage.getItem('auth_token');

    const [branches, setBranches] = useState([]);
    const [categories, setCategories] = useState([
        "notes and information",
        "student activities",
        "announcement"
    ]);

    const resetFilter = () => {
        setCurrentBranchId(null);
        setCurrentCategory(null);
        setCurrentDateRange([null, null]);
        setCurrentKeyword(null);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/react/getBranches`, 
            {
                headers: { 'Authorization': `Bearer ${token}` }
            }
        )
        .then(response => {
            const branches = response.data.data;

            setBranches(branches);
        })
        .catch(error => console.error("Error fetching branches:", error.message));
    }, []);

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="6" xl="2">
                    <label htmlFor="year-intake">Category</label>
                    <Select
                        defaultValue={{ value: currentCategory, label: currentCategory }}
                        options={categories.map(category => ({
                            value: category,
                            label: category
                        }))}
                        className="js-example-basic-single mb-3"
                        onChange={selectedOption => {
                            setCurrentCategory(selectedOption.value);
                        }}
                    />
                </Col>
                <Col sm="6" xl="3">
                    <label htmlFor="branches">Branch</label>
                    <Select
                        value={{ value: currentBranchId, label: branches.find(branch => branch.branch_id === currentBranchId)?.code }}
                        options={branches.map(branch => ({
                            value: branch.branch_id,
                            label: branch.code
                        }))}
                        className="js-example-basic-single mb-3"
                        onChange={selectedOption => {
                            setCurrentBranchId(selectedOption.value);
                        }}
                    />
                </Col>

                <Col sm="6" xl="3">
                    <label htmlFor="datePicker">Daterange</label>
                    <FormGroup  className='w-100 d-flex flex-column'>
                        <DatePicker
                            className="form-control digits"
                            selectsRange={true}
                            startDate={currentStartDate}
                            endDate={currentEndDate}
                            onChange={(update) => {
                                setCurrentDateRange(update);
                            }}
                            isClearable={true}
                            dateFormat={"dd/M/yyyy"} />
                    </FormGroup>
                </Col>

                <Col sm="6" xl="2">
                    <label htmlFor="datePicker">Keyword</label>
                    <Input
                        value={currentKeyword}
                        onChange={(event) => setCurrentKeyword(event.target.value)}
                    />
                </Col>

                <Col sm="6" xl="2">
                    <label htmlFor="datePicker">&nbsp;</label>
                    <Button onClick={() => resetFilter()} color='primary' className='form-control text-white'>Reset Filter</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default MessageFilter;