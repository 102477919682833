import Axios from "axios";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "reactstrap";
import { formatSourceDateToOutput } from "../../../_helper/Date";

const RecipientSelectorTable = ({studentSemesterIds, setStudentSemesterIds, availableStudentSemesters}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const token = localStorage.getItem('auth_token');

    const _ = require("lodash");

    const handleChangeSelection = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        console.log(selectedRows);
    };

    const contextActions = useMemo(() => {
		const handleAdd = () => {
            setToggleClearRows(!toggledClearRows);
            setStudentSemesterIds([...studentSemesterIds, ...(selectedRows.map(row => {
                return row.semester_id;
            }))]);
		};

		return (
			<Button key="plus" onClick={handleAdd} style={{ backgroundColor: 'green' }} icon>
				Add
			</Button>
		);
	}, [data, selectedRows, toggledClearRows]);

    const columns = [
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: true,
                grow: 1
            },
            {
                id: "class_name",
                name: "Class",
                selector: (row) => row.class_name,
                sortable: true,
                grow: 1
            },
            {
                id: "package_name",
                name: "Package",
                selector: (row) => row.package_name,
                sortable: true,
                grow: 1
            },
            {
                id: "branch_code",
                name: "Branch Code",
                selector: (row) => row.branch_code,
                sortable: true,
            }
        ];
    
    return (
        <DataTable
            title=" "
            columns={columns}
            data={availableStudentSemesters}
            progressPending={loading}
            contextActions={contextActions}
            pagination
            selectableRows
            onSelectedRowsChange={handleChangeSelection}
            clearSelectedRows={toggledClearRows}
            />
    );   
}

export default RecipientSelectorTable;