import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../_helper/Utilities/ScrollToTop";

const AuthenticatedWrapper = ({ children }) => {
    const navigate = useNavigate();
    
    const authToken = localStorage.getItem("auth_token");
    
    const isAuthenticated = !!authToken;

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, navigate]);

    if (isAuthenticated) {
        return (<>
            <ScrollToTop />
            {children}
        </>);
    } else {
        return null;
    }
};

export default AuthenticatedWrapper;