import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'

const AlignmentTuneTool = require('editorjs-text-alignment-blocktune');

export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
      tunes: ['centerAlignmentTune'],
    },
    embed: Embed,
    table: Table,
    list: List,
    warning: Warning,
    code: Code,
    linkTool: LinkTool,
    image: {
      class: Image,
      config: {
        endpoints: {
          byFile: process.env.REACT_APP_API_URL + '/api/uploadFile', // Your backend file uploader endpoint
          byUrl: process.env.REACT_APP_API_URL + '/api/fetchUrl', // Your endpoint that provides uploading by Url
        }
      }
    },
    header: {
      class: Header,
      inlineToolbar: true,
      tunes: ['centerAlignmentTune'],
    },
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    centerAlignmentTune: {
      class:AlignmentTuneTool,
      config:{
        default: "left",
        blocks: {
          header: 'center',
          list: 'left'
        }
      },
    }
  }