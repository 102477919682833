import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';

const Profile = () => {
    const [profile, setProfile] = useState(man);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);

        if (token) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/react/user/profile`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                setName((response.data.name || '').toUpperCase());
                setEmail(response.data.email || '');
                if (response.data.picture) {
                    setProfile(response.data.picture);
                }
            })
            .catch(error => {
                console.error("Error fetching profile details:", error);
            });
        }
    }, [token]);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <a className="setting-primary" href="#javascript">
                    <Settings />
                </a>
                <Image attrImage={{ className: 'img-90 rounded-circle', src: profile, alt: name }} />
                <div className="badge-bottom">
                    <div className="badge badge-primary">New</div>
                </div>
                <H6 attrH6={{ className: 'mt-3 f-14 f-w-600 txt-primary' }}>{name}</H6>
                <P attrPara={{ className: 'mb-0 font-roboto' }}>{email}</P>
            </div>
        </Fragment>
    );
};

export default Profile;