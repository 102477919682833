import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

const LogoutClass = () => {
  const navigate = useNavigate();

  const logoutAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/react/logout`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
        }
      });

      const result = await response.json();

      if (result.data && result.data.status === 'success') {
        // Clear localStorage and navigate to login route
        localStorage.removeItem("profileURL");
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth0_profile");
        localStorage.removeItem("Name");
        navigate(`${process.env.PUBLIC_URL}/login`);
      } else {
        // Handle error, maybe show a notification to the user
        console.error('Error logging out:', result);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const Logout = () => {
    logoutAPI();
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown p-0", onClick: Logout }}>
        <Btn attrBtn={{ as: Card.Header, className: "btn btn-primary-light", color: "default" }}>
          <Link to={`${process.env.PUBLIC_URL}/login`}>
            <LogOut />
            Log out
          </Link>
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;