import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from 'reactstrap';

const ProfileDetailsModal = ({ isOpen, student, onClose, branchName, packageName, className }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalHeader toggle={onClose}>Student Details</ModalHeader>
            <ModalBody>
                {student && (
                    <div>
                        <div className="mb-2">
                            <Label className="col-sm-3 col-form-label form-label" for="studentName">Student Name</Label>
                            <span id="studentName">{student.name}</span>
                        </div>
                        <div className="mb-2">
                            <Label className="col-sm-3 col-form-label form-label" for="branchName">Branch Name</Label>
                            <span id="branchName">{branchName}</span>
                        </div>
                        <div className="mb-2">
                            <Label className="col-sm-3 col-form-label form-label" for="packageName">Package Name</Label>
                            <span id="packageName">{packageName}</span>
                        </div>
                        <div>
                            <Label className="col-sm-3 col-form-label form-label" for="className">Class Name</Label>
                            <span id="className">{className}</span>
                        </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ProfileDetailsModal;
