import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import RecipientSelectorTable from "./RecipientSelectorTable";

const StudentSemesterSelectorForm = ({ studentSemesterIds, setStudentSemesterIds }) => {
    const [selectedStatus, setSelectedStatus] = useState("active");
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedStateId, setSelectedStateId] = useState(null);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [selectedStudentSemesterIds, setSelectedStudentSemesterIds] = useState([]);

    const [years, setYears] = useState([]);
    const [states, setStates] = useState([]);
    const [branches, setBranches] = useState([]);
    const [packages, setPackages] = useState([]);
    const [classes, setClasses] = useState([]);
    const [availableStudentSemesters, setAvailableStudentSemesters] = useState([]);

    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        let currentYear = (new Date).getFullYear();
        setYears([currentYear - 1, currentYear, currentYear + 1]);
        setSelectedYear(currentYear);
    }, []);

    useEffect(() => {
        setSelectedStateId(null);
        fetchStates();
    }, [selectedYear]);

    useEffect(() => {
        setSelectedBranchId(null);
        fetchBranches();
    }, [selectedStateId]);

    useEffect(() => {
        console.log("hello");
        fetchAvailableStudentSemesters();
        setSelectedPackageId(null);
        fetchPackages();
    }, [selectedBranchId]);

    useEffect(() => {
        fetchAvailableStudentSemesters();
        setSelectedClassId(null);
        setSelectedName(null);
        fetchClasses();
    }, [selectedPackageId]);

    useEffect(() => {
        fetchAvailableStudentSemesters();
    }, [selectedClassId, studentSemesterIds, selectedName]);

    useEffect(() => {
        fetchAvailableStudentSemesters();
    }, [selectedStatus]);

    const fetchStates = async () => {
        if (selectedYear) {
            let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/states`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {year: selectedYear}
            });

            setStates(response.data.data);
        } else {setStates([]);}
    };

    const fetchBranches = async () => {
        if (selectedStateId) {
            let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/branches`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {state_id: selectedStateId}
            });

            setBranches(response.data.data);
        } else {setBranches([]);}
    };

    const fetchPackages = async () => {
        if (selectedBranchId) {
            let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/packages`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {year: selectedYear, branch_id: selectedBranchId}
            });

            setPackages(response.data.data);
        } else {setPackages([]);}
    };

    const fetchClasses = async () => {
        if (selectedPackageId) {
            let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/classes`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {year: selectedYear, package_id: selectedPackageId, branch_id: selectedBranchId}
            });

            setClasses(response.data.data);
        } else {setClasses([]);}
    };

    const fetchAvailableStudentSemesters = async () => {
        if (selectedBranchId || selectedPackageId || selectedClassId) {
            let response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/messages/create-info/recipients`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    year: selectedYear,
                    package_id: selectedPackageId,
                    branch_id: selectedBranchId,
                    class_id: selectedClassId,
                    status: selectedStatus,
                    selected_student_semester_ids: studentSemesterIds,
                    name: selectedName
                },
            });

            setAvailableStudentSemesters(response.data.data);
        } else {setAvailableStudentSemesters([]);}
    };

    const toogleStudentSemester = (studentSemesterId) => {
        if (selectedStudentSemesterIds.find((value) => studentSemesterId == value)) {
            let unfilteredSelectedStudentSemesterIds = [...selectedStudentSemesterIds];

            unfilteredSelectedStudentSemesterIds.splice(selectedStudentSemesterIds.findIndex((value) => studentSemesterId == value), 1);
    
            let newSelectedStudentSemesterIds = [...new Set(unfilteredSelectedStudentSemesterIds)];
    
            setSelectedStudentSemesterIds(newSelectedStudentSemesterIds);
        } else {
            let unfilteredSelectedStudentSemesterIds = [...selectedStudentSemesterIds];

            unfilteredSelectedStudentSemesterIds.push(studentSemesterId);

            let newSelectedStudentSemesterIds = [...new Set(unfilteredSelectedStudentSemesterIds)];

            setSelectedStudentSemesterIds(newSelectedStudentSemesterIds);
        }
    };

    const toogleAllStudentSemester = () => {
        if (selectedStudentSemesterIds.length < availableStudentSemesters.length) {
            setSelectedStudentSemesterIds(availableStudentSemesters.map(value => value.semester_id));
        } else {
            setSelectedStudentSemesterIds([]);
        }
    }

    const transferStudentSemesters = () => {
        setStudentSemesterIds([...studentSemesterIds, ...selectedStudentSemesterIds]);
        setSelectedStudentSemesterIds([]);
    }

    return (
        <Row>
            <Col sm="12" xl="6">
                <Label for="" className="p-0 pb-0">
                    Year
                </Label>
                <FormGroup className="custom-radio-ml d-flex ms-2 mb-0 px-0 py-2" >
                    {
                        years.map((year, index) => <div className="radio radio-primary me-4" key={`${year}-year`}>
                            <Input
                                id={`radio${index}-year`}
                                type="radio"
                                name="year"
                                value={year}
                                checked={year == selectedYear}
                                onChange={() => setSelectedYear(year)}
                            />
                            <Label for={`radio${index}-year`}>{year}</Label>
                        </div>)
                    }
                </FormGroup>
            </Col>

            <Col sm="12" xl="6">
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="state-input" className="p-0 pb-0">
                        State
                    </Label>
                    <div className="p-0 pt-2">
                        <Input
                            id="state-input"
                            type="select"
                            name="state"
                            className="px-4 py-2"
                            onChange={(event) => setSelectedStateId(event.target.value)}
                        >
                            <option value={null}>Select a state</option>
                            {states.map((state) => {
                                return (
                                    <option value={state.id} key={`${state.id}-package`}>
                                        {state.name}
                                    </option>
                                );
                            })}
                        </Input>
                    </div>
                </FormGroup>
            </Col>

            <Col sm="12" xl="6">
                <Label for="" className="p-0 pb-0">
                    Status
                </Label>
                <FormGroup className="custom-radio-ml d-flex ms-2 mb-0 px-0 py-2">
                    <div className="radio radio-primary me-4">
                        <Input
                            id="radio11"
                            type="radio"
                            name="status"
                            value="active"
                            onChange={() => setSelectedStatus("active")}
                        />
                        <Label for="radio11">Active</Label>
                    </div>
                    <div className="radio radio-primary me-4">
                        <Input
                            id="radio33"
                            type="radio"
                            name="status"
                            value="graduated"
                            onChange={() => setSelectedStatus("graduated")}
                        />
                        <Label for="radio33">Graduated</Label>
                    </div>
                </FormGroup>
            </Col>

            <Col sm="12" xl="6">
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="branch-input" className="p-0 pb-0">
                        Branch Code
                    </Label>
                    <div className="p-0 pt-2">
                        <Input
                            id="branch-input"
                            type="select"
                            name="branch"
                            className="px-4 py-2"
                            onChange={(event) => setSelectedBranchId(event.target.value)}
                        >
                            <option value={null}>Select a branch</option>
                            {branches.map((branchObject) => {
                                return (
                                    <option value={branchObject.branch_id} key={`${branchObject.branch_id}-branch`}>
                                        {branchObject.code}
                                    </option>
                                );
                            })}
                        </Input>
                    </div>
                </FormGroup>
            </Col>

            <Col>
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="package-input" className="p-0 pb-0">
                        Package
                    </Label>
                    <div className="p-0 pt-2">
                        <Input
                            id="package-input"
                            type="select"
                            name="package"
                            className="px-4 py-2"
                            onChange={(event) => setSelectedPackageId(event.target.value)}
                        >
                            <option value={null}>Select a package</option>
                            {packages.map((packageObject) => {
                                return (
                                    <option value={packageObject.package_id} key={`${packageObject.package_id}-package`}>
                                        {packageObject.name}
                                    </option>
                                );
                            })}
                        </Input>
                    </div>
                </FormGroup>
            </Col>

            <Col sm="12" xl="6">
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="class-input" className="p-0 pb-0">
                        Class
                    </Label>
                    <div className="p-0 pt-2">
                        <Input
                            id="class-input"
                            type="select"
                            name="class"
                            className="px-4 py-2"
                            onChange={(event) => setSelectedClassId(event.target.value)}
                        >
                            <option value={null}>Select a class</option>
                            {classes.map((classObject) => {
                                return (
                                    <option value={classObject.cat_id} key={`${classObject.cat_id}-class`}>
                                        {classObject.name}
                                    </option>
                                );
                            })}
                        </Input>
                    </div>
                </FormGroup>
            </Col>

            <Col sm="12" xl="6">
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="class-input" className="p-0 pb-0">
                        Student Name
                    </Label>
                    <div className="p-0 pt-2">
                        <Input
                            id="class-input"
                            type="text"
                            name="class"
                            className="px-4 py-2"
                            value={selectedName}
                            onChange={(event) => setSelectedName(event.target.value)}
                            />
                    </div>
                </FormGroup>
            </Col>
            
            <Col sm="12" xl="6">
                <FormGroup className="d-flex flex-column mb-0">
                    <Label for="class-input" className="p-0 pb-0">
                         &nbsp;
                    </Label>
                    <button type="button" className="form-input btn btn-primary" onClick={() => transferStudentSemesters()}>
                        Add StudentSemesters
                    </button>
                </FormGroup>
            </Col>

            <Col sm="12" className="overflow-y-scroll" style={{ maxHeight: "300px" }}>
                <Label for="" className="p-0 pb-0">
                    Available Student Semesters
                </Label>
                <FormGroup className="d-flex flex-column ms-2 mb-0 px-4 py-2">
                    <RecipientSelectorTable
                        studentSemesterIds={studentSemesterIds}
                        setStudentSemesterIds={setStudentSemesterIds}
                        availableStudentSemesters={availableStudentSemesters}
                        />
                </FormGroup>
            </Col>
        </Row>
    );
};

export default StudentSemesterSelectorForm;
