import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword } from "../Constant";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import man from "../assets/images/dashboard/1.png";
import SocialAuth from "./Tabs/LoginTab/SocialAuth";

const RedirectLogin = ({ selected }) => {
  const { redirectToken } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    redirectLogin();
  }, []);

  const redirectLogin = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/react/redirect-login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ redirect_token: redirectToken })
        });

        const data = await response.json();

        if (data.status === 'success') {
            localStorage.setItem("login", true);
            localStorage.setItem("auth_token", data.token);
            history('/attendances/dashboard');
            setLoading(false);
        } else {
            toast.error(data.message);
            setLoading(false);
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again!");
        setLoading(false);
    }
  };

  return (
    <Fragment>
    </Fragment>
  );
};

export default RedirectLogin;
